import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/community_day/Kogukond päev.png";
import React from "react";

export const CommunityDay = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__community-day'>
            <div className='wrapped__community-day__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.CommunityDay.ThankYou" /></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.CommunityDay.Participation"/></h1>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}