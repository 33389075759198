import {FormattedHTMLMessage} from "react-intl";
import slideHomeBg from "../../../images/wrapped_2024/slides/ev_charging_personal/Elektrilaadija kodulaadija.png";
import slidePublicBg from "../../../images/wrapped_2024/slides/ev_charging_personal/Elektrilaadija avalik laadija.png";
import React from "react";

export const EvChargingPersonal = ({type, data}) => {
    const slideBg = type === 'home' ? slideHomeBg : slidePublicBg;
    const titleId = type === 'home' ? "Wrapped2024.EvChargingPersonal.HomeTitle" : "Wrapped2024.EvChargingPersonal.PublicTitle"
    return (
        <div className='wrapped__carousel-slide wrapped__ev-charging-personal'>
            <div className='wrapped__ev-charging-personal__content'>
                <p><FormattedHTMLMessage id={titleId}/></p>
                <h1>{data.savings}</h1>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}