import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/fuel_general/Kütus general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/fuel_general/Kütus general@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/fuel_general/Kütus general 2.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/fuel_general/Kütus general 2@2x.png";
import React from "react";

export const FuelGeneral = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__fuel-general'>
            <div className='wrapped__fuel-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.FuelGeneral.Title"/></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.FuelGeneral.Distance"/></h1>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
            <div className="imageFrame">
                <img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg'
                     className='wrapped__fuel-general__background'/>
            </div>
        </div>
    );
}