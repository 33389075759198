import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { Button } from "@rmwc/button";
import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import moment from 'moment';
import { GetContractLocale } from "../../helpers/ContractHelper";
import { contains } from "../../helpers/stringHelper";
import { InProgress, Active, Archived } from "../../const/ContractStatus";
import { LatvianElectricity } from "../../const/featureFlags";
import {
    getElectricityContractDetailsPath,
    getFuelContractDetailsPath,
    ContractElectricityPath,
    ContractFuelPath,
    ContractGasPath,
    getGasContractDetailsPath,
    getEvChargingContractDetailsPath,
} from "../../const/routes";
import { CylinderGas, ElectricityNew, GasNew, EvCharging, Fuel } from "../../const/contractType";
import BaseFormComponent from "../common/BaseFormComponent";
import TextFieldGroup from "../common/TextFieldGroup";
import CheckboxField from "../common/CheckboxField";
import Currency from "../common/Currency";
import auth from "../../auth/authenticate";
import classnames from "classnames";
import TableList from "../common/TableList";
import {
    downloadContractDigiDoc,
    downloadContractPdf,
    downloadElmoContractPdf,
    fetchContractLimitApplications,
    acceptNewContractLimit,
    rejectNewContractLimit
} from "../../actions/contractActions";
import AlertMessage from "../common/AlertMessage";
import {IsLatvianWeb} from "../../helpers/AlternativeDesign";
import { CompletedFormStatus } from "../../const/cardApplication";
import { getContractsFilter, setContractsFilter } from "../../cachedFilter";

import "../../styles/helpers/_animations.scss";
import * as ContractStatus from "../../const/ContractStatus";
import * as EleringStatus from "../../const/EleringStatus";
import { Feature } from "flagged";

class ContractsList extends BaseFormComponent {
    static FieldContractExtraNrSearch = "contractExtraNumber";
    static FieldContractType = "contractType";
    static FieldContractStatus = "contractStatus";

    static ContractTypeFilter = [
        {
            label: "Contract.Electricity.Title",
            value: ElectricityNew,
        },
        {
            label: "Contract.Gas.Title",
            value: GasNew,
        },
        {
            label: "Contract.Fuel.Title",
            value: Fuel,
        },
        {
            label: "Contract.EvCharging.Title",
            value: EvCharging,
        },
        {
            label: "Contract.CylinderGas.Title",
            value: CylinderGas
        }
    ];
    static ContractStatusFilter = [
        {
            label: "Contract.Status.InProgress",
            value: InProgress,
        },
        {
            label: "Contract.Status.Active",
            value: Active,
        },
        {
            label: "Contract.Status.Archived",
            value: Archived,
        }
    ];

    static TableHeaders = [
        {
            label: "ContractsPage.Contract.Nr",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.Type",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.Status",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.Start",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.End",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.LimitBalance",
            colspan: 1
        },
        {
            label: "ContractsPage.Contract.BookingAmount",
            colspan: 4
        },
    ];
    constructor(props) {
        super(props);

        const userRole = auth.getRole();
        const cachedFilter = getContractsFilter();

        this.state = {
            filter: {
                contractExtraNumber: cachedFilter.contractExtraNumber,
                contractType: cachedFilter.contractType,
                contractStatus: cachedFilter.contractStatus,
            },
            isCompany: userRole ? userRole.isCompany : false,
            acceptedLimitApplications: [],
            rejectedLimitApplications: []
        };

        this.onAcceptLimitIncrease = this.onAcceptLimitIncrease.bind(this);
        this.onRejectLimitIncrease = this.onRejectLimitIncrease.bind(this);
        this.onChangeExtraNumberFilter = this.onChangeExtraNumberFilter.bind(this);
    }

    componentDidMount() {
        if (!this.props.contractLimitApplicationsFetched && !this.props.contractLimitApplicationsFetching) {
            this.props.fetchContractLimitApplications();
        }

        if (this.props.evChargingContractFinalizeFetched && !this.props.evChargingContractFinalizeError) {
            const filterCopy = {...this.state.filter};
            filterCopy.contractType = [...new Set([...filterCopy.contractType, EvCharging])];
            this.setState({ filter: filterCopy });
            setContractsFilter(filterCopy);
        }
    }

    renderDateWithLabel(date, translationid) {
        return (
            <>
                <FormattedMessage
                    id={translationid}
                />&nbsp;{date.format("DD.MM.YYYY")}
            </>
        );
    }

    getCompanyInProgressPath(contract, mainPath) {
        const company = auth.getRole();
        return `${mainPath}/offer/${contract.id}/${company.nationalIdentityNumber}`;
    }

    renderTableRowJson(contract) {
        const startDate = moment(contract.start, "YYYY-MM-DD HH:mm:ss");
        const endDate = moment(contract.end, "YYYY-MM-DD HH:mm:ss");
        let contractLocale = GetContractLocale(contract.type);

        const status = contract.eleringStatus === EleringStatus.Denied
            ? ContractStatus.InProgress
            : contract.status;

        let result = {
            content: [
                {
                    colspan: 1,
                    content: contract.type === Fuel
                        ? contract.extraNumber
                        : contract.number
                },
                {
                    colspan: 1,
                    content: <FormattedMessage id={contractLocale} />
                },
                {
                    colspan: 1,
                    content: <span className={`status-label status-label--${status}`}>
                        <FormattedMessage id={`Contract.Status.${status}`} />
                    </span>
                },
                {
                    colspan: 1,
                    content: startDate.format("DD.MM.YYYY")
                },
                {
                    colspan: 1,
                    content: contract.end ? endDate.format("DD.MM.YYYY") : <FormattedMessage id="ContractsPage.Contract.NoEnd" />
                },
                {
                    colSpan: 1,
                    content: contract.type === Fuel
                        ? <Currency value={contract.limitBalance} />
                        : null
                },
                {
                    colSpan: 1,
                    content: contract.type === Fuel
                        ? <Currency value={contract.bookingAmount} />
                        : null
                }
            ]
        }

        if (contract.status === InProgress) {
            const mainPath = contract.type === ElectricityNew ? ContractElectricityPath : ContractGasPath;
            const path = this.state.isCompany
                ? this.getCompanyInProgressPath(contract, mainPath)
                : mainPath;
            result.content.push({
                colspan: 1,
                content: <a href={path} className="mdc-button mdc-button--text mdc-button--secondary">
                    <FormattedMessage id="Contract.Open" />
                </a>
            })
        } else {
            let path = "";
            switch (contract.type) {
                case GasNew:
                    path = getGasContractDetailsPath(contract.id);
                    break;
                case ElectricityNew:
                    path = getElectricityContractDetailsPath(contract.id);
                    break;
                case EvCharging:
                    path = getEvChargingContractDetailsPath(contract.id);
                    break;
                case Fuel:
                    path = getFuelContractDetailsPath(contract.id);
                    break;
                default:
                    break;
            }

            let content =
                <a href={path} rel="noopener noreferrer" className="mdc-button mdc-button--text mdc-button--secondary">
                    <FormattedMessage id="Contract.Open" />
                </a>;

            result.content.push({
                colspan: 1,
                content: content
            });
        }
        if (contract.type === GasNew || contract.type === ElectricityNew) {
            result.content.push({
                colspan: 1,
                content:
                    <Button
                        className="mdc-button--text mdc-button--secondary bold"
                        onClick={() => this.props.downloadElmoContractPdf(contract.id, contract.type === Fuel ? contract.extraNumber : contract.number)} >
                        <i className="icon icon-pdf pr-10"></i>
                        <FormattedMessage id="ContractsPage.Contract.Pdf" />
                    </Button>
            })
        }
        if (contract.digiDocContainerName) {
            result.content.push({
                colspan: 1,
                content:
                    <Button
                        className="mdc-button--text mdc-button--secondary bold"
                        onClick={() => this.props.downloadContractPdf(contract.digiDocContainerName)}>
                        <i className="icon icon-pdf pr-10"></i>
                        <FormattedMessage id="ContractsPage.Contract.Pdf" />
                    </Button>
            });
            result.content.push({
                colspan: 1,
                content:
                    <Button
                        className="mdc-button--text mdc-button--secondary bold"
                        onClick={() => this.props.downloadContractDigiDoc(contract.digiDocContainerName)}>
                        <i className="icon icon-pdf pr-10"></i>
                        <FormattedMessage id="ContractsPage.Contract.DigiDoc" />
                    </Button>
            });
        }

        return result;
    }

    onChangeCheckboxToFilter(fieldName, item) {
        let filtersCopy = [...this.state.filter[fieldName]];

        if (filtersCopy.includes(item.value)) {
            filtersCopy.splice(filtersCopy.indexOf(item.value), 1);
        } else {
            filtersCopy = filtersCopy.concat([item.value]);
        }

        const newFilter = this.state.filter;
        newFilter[fieldName] = filtersCopy;

        setContractsFilter(newFilter);

        this.setState({
            filter: newFilter
        });
    }

    onChangeExtraNumberFilter(e) {
        const filterCopy = this.state.filter;
        filterCopy[ContractsList.FieldContractExtraNrSearch] = e.target.value;
        this.setState({
            filter: filterCopy
        });
        setContractsFilter(filterCopy);
    }

    renderFilter() {
        return (
            <GridInner className="filter-list mb-30">
                <GridCell desktop={3}>
                    <TextFieldGroup
                        field={ContractsList.FieldContractExtraNrSearch}
                        onChange={this.onChangeExtraNumberFilter}
                        value={this.state.filter.contractExtraNumber || ""}
                        placeholder={this.props.intl.formatMessage({
                            id: "ContractsPage.Contract.InsertNr"
                        })}
                        label="ContractsPage.Contract.Nr" />
                </GridCell>
                <GridCell desktop={4}>
                    <Typography
                        use="body1"
                        className="mdc-typography mdc-typography--body1--bold mb-10">
                        <FormattedMessage id="ContractsPage.Contract.Type" />
                    </Typography>
                    <Feature name={LatvianElectricity}>
                        {isEnabled =>
                            ContractsList.ContractTypeFilter
                                .filter(x => !IsLatvianWeb() ||
                                    (isEnabled && (x.value === GasNew || x.value === ElectricityNew)) ||
                                    (!isEnabled && x.value === GasNew))
                                .map(x =>
                                    <CheckboxField
                                        className="mr-5"
                                        containerClassName="pr-10 mb-10"
                                        field={'filter-' + x.value}
                                        key={'filter-' + x.value}
                                        checked={this.state.filter.contractType.includes(x.value)}
                                        label={this.props.intl.formatMessage({ id: x.label })}
                                        onChange={this.onChangeCheckboxToFilter.bind(this, ContractsList.FieldContractType, x)}
                                    />
                        )}
                    </Feature>
                </GridCell>
                <GridCell desktop={5}>
                    <Typography
                        use="body1"
                        className="mdc-typography mdc-typography--body1--bold mb-10">
                        <FormattedMessage id="ContractsPage.Contract.Status" />
                    </Typography>
                    {ContractsList.ContractStatusFilter.map(x =>
                        <CheckboxField
                            className="mr-5"
                            containerClassName="pr-10 mb-10"
                            field={'filter-' + x.value}
                            key={'filter-' + x.value}
                            checked={this.state.filter.contractStatus.includes(x.value)}
                            label={
                                <FormattedMessage id={x.label} />
                            }
                            onChange={this.onChangeCheckboxToFilter.bind(this, ContractsList.FieldContractStatus, x)}
                        />
                    )}
                </GridCell>
            </GridInner>
        );
    }

    renderButtons() {
        return (
            <GridInner className="mb-30">
                {this.state.isCompany &&
                    <GridCell span={12} className={classnames("align-right align-right--desktop")}>
                        <Button
                            unelevated
                            tag={Link}
                            to={ContractFuelPath}
                        >
                            <FormattedMessage id="ContractsPage.Button.OrderPaymentCard" />
                        </Button>
                    </GridCell>
                }
            </GridInner>
        );
    }

    onAcceptLimitIncrease(limitApplicationId) {
        this.props.acceptNewContractLimit(limitApplicationId).then(() => {
            this.setState({
                acceptedLimitApplications: [...this.state.acceptedLimitApplications, limitApplicationId]
            });
        });
    }

    onRejectLimitIncrease(limitApplicationId) {
        this.props.rejectNewContractLimit(limitApplicationId).then(() => {
            this.setState({
                rejectedLimitApplications: [...this.state.rejectedLimitApplications, limitApplicationId],
            });
        });
    }

    renderLimitApplicationSection() {
        if (!this.state.isCompany || this.props.contractLimitApplications.length === 0) {
            return null;
        }

        if (this.props.acceptNewContractLimitError || this.props.rejectNewContractLimitError) {
            return <AlertMessage
                type={AlertMessage.TYPE_ALERT}
                title={<FormattedMessage id="Error.Technical" />}
                className="mb-25"
            />
        }

        const hasNoChildren = this.state.acceptedLimitApplications.length +
            this.state.rejectedLimitApplications.length === this.props.contractLimitApplications.length;

        return (
            <GridInner className={hasNoChildren ? "remove-gap" : "mb-25"}>
                {this.props.contractLimitApplications?.map((data, i) => {
                    const isAccepted = this.state.acceptedLimitApplications.indexOf(data.limitApplicationId) > -1;
                    const isRejected = this.state.rejectedLimitApplications.indexOf(data.limitApplicationId) > -1;

                    return (
                        <GridCell span={12} key={i} className={isAccepted || isRejected ? "fade-out" : ""}>
                            {isAccepted &&
                                <AlertMessage
                                    type={AlertMessage.TYPE_DONE}
                                    title={<FormattedMessage id="ContractsPage.LimitApplication.AcceptMessage"
                                        values={{
                                            contractNumber: data.contractNumber,
                                            amount: data.requestedLimit
                                        }} />}
                                />
                            }
                            {!isAccepted &&
                                <AlertMessage
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={<FormattedMessage id="ContractsPage.LimitApplication.Message"
                                        values={{
                                            contractNumber: data.contractNumber,
                                            amount: data.requestedLimit
                                        }} />}
                                >
                                    {!isRejected &&
                                        <>
                                            <Button
                                                outlined
                                                onClick={() => this.onRejectLimitIncrease(data.limitApplicationId)}
                                                className="mdc-button--outlined mdc-button--dark mr-15">
                                                <FormattedMessage id="ContractsPage.LimitApplication.Reject" />
                                            </Button>
                                            <Button unelevated onClick={() => this.onAcceptLimitIncrease(data.limitApplicationId)}>
                                                <FormattedMessage id="ContractsPage.LimitApplication.Accept" />
                                            </Button>
                                        </>
                                    }
                                </AlertMessage>
                            }
                        </GridCell>)
                })
                }
            </GridInner>
        );
    }

    renderCylinderGasContractSuccessMessage() {
        return this.props.location?.state?.contractStatus === CompletedFormStatus.ACCEPTED &&
            <GridCell span={12} className="mb-30">
                <AlertMessage
                    type={AlertMessage.TYPE_DONE}
                    title={<FormattedMessage id="ContractsPage.CylinderGas.Success" />}
                />
            </GridCell>
    }

    render() {
        let rows = this.props.items;
        if (this.state.filter.contractExtraNumber) {
            rows = rows.filter(x => contains(x.extraNumber, this.state.filter.contractExtraNumber) ||
                                    contains(x.number, this.state.filter.contractExtraNumber));
        }
        rows = rows.filter(x => this.state.filter.contractType.includes(x.type))
        rows = rows.filter(x => this.state.filter.contractStatus.includes(x.status))

        const tableRowsJson = rows.map(item => this.renderTableRowJson(item));

        return (
            <>
                {this.props.items.length > 0 &&
                    <>
                        {this.renderFilter()}
                        {this.renderLimitApplicationSection()}
                        {this.renderCylinderGasContractSuccessMessage()}
                        <TableList
                            headers={ContractsList.TableHeaders}
                            itemContent={tableRowsJson}
                        />
                    </>
                }
            </>
        );
    }
}

ContractsList.propTypes = {
    items: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        contractLimitApplicationsFetched: state.contractLimitApplications.fetched,
        contractLimitApplicationsFetching: state.contractLimitApplications.fetching,
        contractLimitApplications: state.contractLimitApplications.data,

        acceptNewContractLimitError: state.acceptNewContractLimit.error,
        rejectNewContractLimitError: state.rejectNewContractLimit.error,

        evChargingContractFinalizeFetched: state.evChargingContractFinalize.fetched,
        evChargingContractFinalizeError: state.evChargingContractFinalize.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        downloadContractDigiDoc,
        downloadContractPdf,
        downloadElmoContractPdf,
        fetchContractLimitApplications,
        acceptNewContractLimit,
        rejectNewContractLimit
    }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(ContractsList)));