import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/gas_personal/Gaas personal.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/gas_personal/Gaas personal@2x.png";
import React from "react";
import graph from "../../../images/wrapped_2024/slides/gas_personal/Group 300.png";
import graph_2x from "../../../images/wrapped_2024/slides/gas_personal/Group 300@2x.png";

export const GasPersonal = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__gas-personal'>
            <div className='wrapped__gas-personal__content'>
                <img src={graph} srcSet={`${graph} 1x, ${graph_2x} 2x`} alt='graph'/>
                <h1><FormattedHTMLMessage id="Wrapped2024.GasPersonal.Content"/></h1>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}