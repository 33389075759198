import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment"
import { Typography } from "@rmwc/typography";
import { Grid, GridCell, GridInner } from "@rmwc/grid";

import { fetchPaymentCardInvoice, downloadPaymentCardInvoicePdf } from "../actions/invoiceActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";

import {
    InvoicePaymentButton,
    InvoicePdfButton,
    PaymentCardInvoiceServicesTable,
    InvoiceHeader,
    InvoiceBackButton,
    InvoiceLine
} from "../components/Invoice";
import Currency from "../components/common/Currency";
import TableList from "../components/common/TableList";
import { TransactionsPathWithPaymentCardInvoiceFilter } from "../const/routes";
import { InvoiceType, COMPANY_USER_INVOICE_MAX_LIMIT } from "../const/Invoice";
import auth from "../auth/authenticate";

class InvoicePaymentCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isCompany: auth.isCompany()
        };

        this.handleInvoicePdfButtonClick = this.handleInvoicePdfButtonClick.bind(this);
        this.renderInvoiceDetails = this.renderInvoiceDetails.bind(this);
        this.renderInvoiceBalance = this.renderInvoiceBalance.bind(this);
    }

    componentDidMount() {
        this.props.fetchPaymentCardInvoice(this.props.match.params.id);
    }

    handleInvoicePdfButtonClick() {
        this.props.downloadPaymentCardInvoicePdf(this.props.invoice.id);
    }

    renderInvoiceDetails() {
        const { invoice } = this.props;
        const invoiceDeadline = invoice.deadline && moment(invoice.deadline);
        const invoiceTime = invoice.invoiceTime && moment(invoice.invoiceTime);

        return (
            <GridInner>
                <GridCell span={4}>
                    <Typography
                        use="body1"
                        className="mdc-typography mdc-typography--body1--bold">
                        {invoice.clientName}
                    </Typography>
                    <Typography
                        use="body1"
                        className="mdc-typography">
                        {invoice.clientLegalAddress}
                    </Typography>
                </GridCell>
                <GridCell desktop={6} tablet={4} phone={4} className="mdc-layout-grid__cell--start-7-desktop">
                    <InvoiceLine
                        name={<FormattedMessage id="Invoice.PaymentCard.Details.InvoiceTime" />}
                        value={invoiceTime.format("DD.MM.YY")} />
                    <InvoiceLine
                        bold
                        name={<FormattedMessage id="Invoice.PaymentCard.Details.Deadline" />}
                        value={invoiceDeadline.format("DD.MM.YY")} />
                    <InvoiceLine
                        name={<FormattedMessage id="Invoice.Details.Reference" />}
                        value={invoice.referenceNumber} />
                    <InvoiceLine
                        name={<FormattedMessage id="Invoice.Details.ContractNumber" />}
                        value={invoice.contractNumber} />
                </GridCell>
            </GridInner >
        );
    }

    renderInvoicePayments() {
        const { invoice } = this.props;

        const headers = [{ label: "Makse liik" }, { label: "Kuupäev" }, { label: "Summa", className: "right" }];
        const rows = invoice.payments.map(x => ({
            content: [
                { content: x.type },
                { content: moment(x.date).format("DD.MM.YYYY") },
                { content: <Currency value={x.amount} />, className: "right" }
            ]
        }));

        return (
            <>
                {invoice.payments?.length > 0
                    ? <GridInner className="mt-mobile-30">
                        <GridCell span={12}>
                            <TableList headers={headers} itemContent={rows} />
                        </GridCell>
                    </GridInner>
                    : null}
            </>
        );
    }

    renderInvoiceBalance() {
        const { invoice } = this.props;

        return (
            <>
                <InvoiceLine
                    name={<FormattedMessage id="Invoice.PaymentCard.Period" />}
                    value={`${moment(invoice.periodStart).format("DD.MM.YYYY")} - ${moment(invoice.periodEnd).format("DD.MM.YYYY")}`} />
                <InvoiceLine
                    name={<FormattedMessage id="Invoice.PaymentCard.Period.BalanceAtStart" />}
                    value={<Currency value={invoice.debt > 0 ? invoice.debt : invoice.prePayment} />} />
                <InvoiceLine
                    name={<FormattedMessage id="Invoice.PaymentCard.Period.Payments" />}
                    value={<Currency value={invoice.periodPaymentsSum} />} />
                <InvoiceLine
                    name={<FormattedMessage id="Invoice.PaymentCard.Period.Receivables" />}
                    value={<Currency value={invoice.periodReceivables} />} />
                <InvoiceLine
                    bold
                    name={<FormattedMessage id="Invoice.PaymentCard.Period.Total" />}
                    value={<Currency value={invoice.periodTotal} />} />
            </>
        )
    }

    renderErrorMessage() {
        return (
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="Invoice.Error" />}
                />
            </GridCell>
        );
    }

    renderInvoice() {
        const { invoice, invoicePdfFetching } = this.props;

        return (
            <>
                <GridCell span={12}>
                    <GridInner>
                        {this.state.isCompany &&
                            <GridCell span={12}>
                                <AlertMessage
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={
                                        <FormattedMessage
                                            id="Invoice.PaymentCard.PaymentLimitNotice"
                                            values={{ limit: COMPANY_USER_INVOICE_MAX_LIMIT }} />
                                    } />
                            </GridCell>
                        }
                        <GridCell desktop={7} tablet={8} phone={4} align="middle" className="invoice__header">
                            <InvoiceHeader invoice={invoice} />
                        </GridCell>
                        <GridCell desktop={5} tablet={8} phone={4} className="text-right invoice__actions">
                            <>
                                <div className="links">
                                    {this.props.type === InvoiceType.PaymentCard &&
                                        <Link to={TransactionsPathWithPaymentCardInvoiceFilter(invoice.number)}
                                              className="mdc-button mdc-button--link mdc-button--small mdc-theme--secondary mr-45 mr-mobile-clear">
                                            <FormattedMessage id="Invoice.ViewTransactions"/>
                                        </Link>
                                    }
                                    <InvoicePdfButton onClick={this.handleInvoicePdfButtonClick} loading={invoicePdfFetching} />
                                </div>
                                <InvoicePaymentButton invoice={invoice} type={InvoiceType.PaymentCard} />
                            </>
                        </GridCell>
                    </GridInner>
                </GridCell>
                <GridCell span={12} className="invoice__details">
                    {this.renderInvoiceDetails()}
                </GridCell>
                <GridCell span={12} className="invoice__services">
                    <PaymentCardInvoiceServicesTable invoice={invoice} />
                </GridCell>
                <GridCell desktop={6} tablet={4} phone={4} className="invoice_payments">
                    {this.renderInvoicePayments()}
                </GridCell>
                <GridCell desktop={4} tablet={4} phone={4} className="mdc-layout-grid__cell--start-9-desktop mdc-layout-grid__cell--start-5-tablet invoice__balance">
                    {this.renderInvoiceBalance()}
                </GridCell>
            </>
        );
    }

    render() {
        const { invoiceFetching, invoiceFetched, invoice } = this.props;
        const hasInvoice = invoiceFetched && Object.keys(invoice).length > 0;

        const renderInvoiceOrError = () => {
            return hasInvoice
                ? this.renderInvoice()
                : this.renderErrorMessage();
        }

        return (
            <Grid className="mdc-layout-grid--base mb-60 invoice invoice--payment-card">
                <GridCell span={12}>
                    <InvoiceBackButton />
                </GridCell>
                {invoiceFetching
                    ? <GridCell span={12}>
                        <Loader type={Loader.TYPE_CENTER} />
                    </GridCell>
                    : renderInvoiceOrError()
                }
            </Grid >
        );
    }
}

function mapStateToProps(state) {
    return {
        invoice: state.invoice.data,
        invoiceFetching: state.invoice.fetching,
        invoiceFetched: state.invoice.fetched,
        invoicePdfFetching: state.invoicePdf.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaymentCardInvoice,
        downloadPaymentCardInvoicePdf
    }, dispatch);
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(InvoicePaymentCard))
);