import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/community_general/Kogukond general.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const CommunityGeneral = ({data}) => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__community-general'>
            <div className='wrapped__community-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.CommunityGeneral.Members" values={{count: toDecimalString(data.memberCount, intl.locale, 0)}}/></p>
                <h1>{toDecimalString(data.treesPlanted, intl.locale, 0)}</h1>
                <h3><FormattedHTMLMessage id="Wrapped2024.CommunityGeneral.SubText"/></h3>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}