import { InvoiceType } from "./Invoice";

export const StartPath = "/";
export const RolesPath = "/roles";
export const AudiCampaignPath = "/audi";
export const ConsumptionPath = "/consumption";
export const ContractsPath = "/contracts";
export const CardsPath = "/cards";
export const InvoicesPath = "/invoices";
export const TransactionsPath = "/transactions";
export const SettingsPath = "/settings";
export const ContactPath = "/settings/user/contact";
export const MainContactPath = "/contact";
export const LoginPath = "/login";
export const LoginCallbackPath = "/logincallback";
export const CommunityPath = "/community";
export const GiftCardPath = "/giftcard";
export const FuelTaxiOrderPath = "/fueltaxiorder";
export const StationsPath = "/stations";
export const ServicePath = "/service";
export const ContractElectricityPath = "/service/contract/electricity";
export const ContractFuelPath = "/service/contract/fuel";
export const ContractGasPath = "/service/contract/gas";
export const ContractThankYouPath = "/service/contract/thankyou";
export const ContractSignedPath = "/service/contract/signed";
export const ContractProductChangeThankYouPath = "/contract/productchange/thankyou"
export const ContractRenewalThankYouPath = "/contract/renew/thankyou"
export const CardOrderReferralPath = "/cardorder";
export const DigitalStampsPath = "/digitalstamps";
export const DigitalStampsClientPath = "/digitalstampsclient";
export const DigitalStampsClientFriendlyPath = "/kliendi-digitemplid";
export const UnsubscribeEmailsPath = "/unsubscribe";
export const TranslationSettingsPath = "/settings/translation";
export const UserManagementSettingsPath = "/settings/usermanagement";
export const StockPriceAlertsPath = "/stock-price-alerts";
export const NordpoolPath = "/nordpool";
export const ProductionPath = "/production";
export const AuthorizationsPath = "/authorizations";
export const InvoicePaymentResponsePath = "/invoice-payment-response";
export const PaymentCardsPath = "/paymentcards";
export const PaymentsPath = "/payments";
export const NotificationsPath = "/notifications";
export const StatisticsPath = "/statistics";
export const ContractEVChargingPath = "/contract/evcharging";
export const PersonalizeBonuscardPath = "/isikusta";
export const SummerCampaignPath = "/summer-campaign";

export const MarketingNotificationsPath = "/marketing-notifications";
export const DebugPath = "/debug";
export const HelpAndContactsPath = "/helpandcontacts";
export const HintHistoryPath = "/hint-history";
export const AppCampaignsPath = "/app-campaigns";

export const AgentServicePath = "/agent/service";
export const AgentElectricityPath = "/agent/electricity";
export const AgentGasPath = "/agent/gas";
export const AgentCommunityPath = "/agent/community";
export const AgentContractThankYouPath = "/agent/contract/thankyou";

export const BasketballCommunityPath = "/basketballcommunity";

export const FamilyGroupPath = "/familygroup";
export const PartnerApiPath = "/partner-api";

export const SmsLoginPagePath = "/sms";
export const ErrorPagePath = "/error";

export const EParakstsCallbackPath = "/eparaksts/callback";

export const ReadingsGasPath = "/readings/gas";

export const FuelPlusElectricityPath = "/fuel-plus-electricity"

export const WrappedPath = "/wrapped-2024"

export const getElectricityContractRenewalPath = (id) => "/contract/renew/electricity/" + (!!id ? id : ":id");

export const getInvoiceDetailsPath = (id, type) => {
    switch (type) {
        case InvoiceType.PaymentCard:
            return getPaymentCardInvoiceDetailsPath(id);
        case InvoiceType.ElectricityAndGas:
            return getElectricityInvoiceDetailsPath(id)
        case InvoiceType.EvCharging:
            return getEvChargingInvoiceDetailsPath(id);
    }
};
export const getPaymentCardInvoiceDetailsPath = (id) => "/invoices/payment-card/" + (!!id ? id : ":id");
export const getElectricityInvoiceDetailsPath = (id) => "/invoices/electricity/" + (!!id ? id : ":id");
export const getEvChargingInvoiceDetailsPath = (id) => "/invoices/ev-charging/" + (!!id ? id : ":id");
export const getCardDetailsPath = (id) => "/cards/" + (!!id ? id : ":id");
export const getElectricityContractDetailsPath = (id) => "/contracts/electricity/" + (!!id ? id : ":id");
export const getGasContractDetailsPath = (id) => "/contracts/gas/" + (!!id ? id : ":id");
export const getFuelContractDetailsPath = (id) => "/contracts/fuel/" + (!!id ? id : ":id");
export const getEvChargingContractDetailsPath = (id) => "/contracts/evcharging/" + (!!id ? id : ":id")

export const getElectricityContractChangePath = (id) => "/contract/productchange/electricity/" + (!!id ? id : ":id");
export const getGasContractChangePath = (id) => "/contract/productchange/gas/" + (!!id ? id : ":id");
export const getElectricityContractOfferPath = (id) => "/service/contract/electricity/offer/" + (!!id ? id : ":id");
export const getGasContractOfferPath = (id) => "/service/contract/gas/offer/" + (!!id ? id : ":id");

export const getCardContractOfferPath = (id) => "/service/contract/card/offer/" + (!!id ? id : ":id");
export const getCylinderGasContractOfferPath = (id) => "/service/contract/cylindergas/offer/" + (!!id ? id : ":id");
export const getEvChargingContractOfferPath = (id) => "/service/contract/evcharging/offer/" + (!!id ? id : ":id");
export const getEvChargingContractOfferAgentPath = (id, agentId) => `/service/contract/evcharging/offer/${id ?? ":id"}/agent/${agentId ?? ":agentId"}`;

export const getElectricityContractProductPreSelectPath = (productCode) => "/service/contract/electricity/" + (!!productCode ? productCode : ":productCode");
export const getGasContractProductPreSelectPath = (productCode) => "/service/contract/gas/" + (!!productCode ? productCode : ":productCode");

export const TransactionsPathWithPaymentCardInvoiceFilter = (invoiceNumber) => ({
    pathname: TransactionsPath,
    state: {
        params: {
            paymentCardTab: true,
            filter: {
                invoiceNumber: invoiceNumber,
                startDate: null,
                endDate: null
            }
        }
    }
});
