export const FamilyGroupAsHidden = "FamilyGroupAsHidden";
export const FamilyGroupAsPublic = "FamilyGroupAsPublic";
export const PrintPaperReceiptSetting = "PrintPaperReceiptSetting";
export const GasoMeterSubmit = "GasoMeterSubmit"
// Enables Summer campaign 2024 as active campaign.
export const LotteryCampaign = "LotteryCampaign";
// Enables visibility of all Summer campaign 2024 feature elements.
export const LotteryCampaign2024 = "LotteryCampaign2024";
export const LatvianElectricity = "LatvianElectricity";
// Enables Wrapped campaign 2024 as active campaign.
export const Wrapped2024 = "Wrapped2024";