import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/electricity_personal_bors/Elekter person bors.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const ElectricityPersonalBors = () => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__el-personal-bors'>
            <div className='wrapped__el-personal-bors__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.ElectricityPersonalBors.Title"/></p>
                <div>
                    <h3><FormattedHTMLMessage id="Wrapped2024.General.April"/>:</h3>
                    <span className="top"><h1>{toDecimalString(7.37, intl.locale, 2)}</h1><h3>s/kWh</h3></span>
                </div>
                <div>
                    <h3><FormattedHTMLMessage id="Wrapped2024.General.March"/>:</h3>
                    <h3>{toDecimalString(8.32, intl.locale, 2)} s/kWh</h3>
                </div>
                <div>
                    <h3><FormattedHTMLMessage id="Wrapped2024.General.February"/>:</h3>
                    <h3>{toDecimalString(9.21, intl.locale, 2)} s/kWh</h3>
                </div>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}