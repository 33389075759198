import {FormattedHTMLMessage, useIntl} from "react-intl";
import eel from "../../../images/wrapped_2024/slides/gas_general/european-eel-nationalgeographic_2720466 1.png";
import eel_2x from "../../../images/wrapped_2024/slides/gas_general/european-eel-nationalgeographic_2720466 1@2x.png";
import eel2 from "../../../images/wrapped_2024/slides/gas_general/european-eel-nationalgeographic_2720466 2.png";
import eel2_2x from "../../../images/wrapped_2024/slides/gas_general/european-eel-nationalgeographic_2720466 2@2x.png";
import slideBg from "../../../images/wrapped_2024/slides/gas_general/Gaas general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/gas_general/Gaas general@2x.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const GasGeneral = ({data}) => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__gas-general'>
            <div className='wrapped__gas-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.GasGeneral.Title"/></p>
                <h1><span className="big"><FormattedHTMLMessage id="Wrapped2024.GasGeneral.Five"/></span><br/>
                    <FormattedHTMLMessage id="Wrapped2024.GasGeneral.LakeAmount"/></h1>
                <h2><FormattedHTMLMessage id="Wrapped2024.GasGeneral.Consumption" values={{consumption: toDecimalString(data.consumedKm3, intl.locale, 1)}}/></h2>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
            <img src={eel2} srcSet={`${eel2} 1x, ${eel2_2x} 2x`} alt='eel' className="wrapped__gas-general__background-eel"/>
            <img src={eel} srcSet={`${eel} 1x, ${eel_2x} 2x`} alt='eel' className="wrapped__gas-general__foreground-eel"/>
        </div>
    );
}