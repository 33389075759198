import {FormattedHTMLMessage, useIntl} from "react-intl";
import {capitalize} from "../../../helpers/stringHelper";
import React from "react";
import hotDogImg from "../../../images/wrapped_2024/slides/food_top3/HOT DOG.png";
import hotDogImg_2x from "../../../images/wrapped_2024/slides/food_top3/HOT DOG@2x.png";
import burgerImg from "../../../images/wrapped_2024/slides/food_top3/BURGER.png";
import burgerImg_2x from "../../../images/wrapped_2024/slides/food_top3/BURGER@2x.png";
import wrapillaImg from "../../../images/wrapped_2024/slides/food_top3/WRAPILLA.png";
import wrapillaImg_2x from "../../../images/wrapped_2024/slides/food_top3/WRAPILLA@2x.png";
import praadImg from "../../../images/wrapped_2024/slides/food_top3/PRAAD.png";
import praadImg_2x from "../../../images/wrapped_2024/slides/food_top3/PRAAD@2x.png";
import lasteeineImg from "../../../images/wrapped_2024/slides/food_top3/LASTEEINE.png";
import lasteeineImg_2x from "../../../images/wrapped_2024/slides/food_top3/LASTEEINE@2x.png";
import suppImg from "../../../images/wrapped_2024/slides/food_top3/SUPP.png";
import suppImg_2x from "../../../images/wrapped_2024/slides/food_top3/SUPP@2x.png";
import puderImg from "../../../images/wrapped_2024/slides/food_top3/PUDER.png";
import puderImg_2x from "../../../images/wrapped_2024/slides/food_top3/PUDER@2x.png";
import juustupallImg from "../../../images/wrapped_2024/slides/food_top3/KIIREAMPS-JUUSTUPALL.png";
import juustupallImg_2x from "../../../images/wrapped_2024/slides/food_top3/KIIREAMPS-JUUSTUPALL@2x.png";
import slideBg from "../../../images/wrapped_2024/slides/food_single/Toit ootuspärane.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/food_single/Toit ootuspärane@2x.png";

export const FoodSingle = ({data}) => {
    const intl = useIntl();
    const getLocalizedFoodName = (data) => {
        if (intl.locale === "en") return data.foodEn;
        if (intl.locale === "ru") return data.foodRu;
        return data.foodEe;
    }

    const renderImage = () => {
        let image;
        let image_2x;
        if (data.group.includes("HOT DOG")) {
            image = hotDogImg;
            image_2x = hotDogImg_2x;
        }
        if (data.group.includes("BURGER")) {
            image = burgerImg;
            image_2x = burgerImg_2x;
        }
        if (data.group.includes("WRAPILLA")) {
            image = wrapillaImg;
            image_2x = wrapillaImg_2x;
        }
        if (data.group.includes("PRAED")) {
            image = praadImg;
            image_2x = praadImg_2x;
        }
        if (data.group.includes("LASTEEINED")) {
            image = lasteeineImg;
            image_2x = lasteeineImg_2x;
        }
        if (data.group.includes("PUDER")) {
            image = puderImg;
            image_2x = puderImg_2x;
        }
        if (data.group.includes("SUPID")) {
            image = suppImg;
            image_2x = suppImg_2x;
        }
        if (data.group.includes("AMPSUD")) {
            image = juustupallImg;
            image_2x = juustupallImg_2x;
        }

        if (!image) {
            return null;
        }

        return <img src={image} srcSet={`${image} 1x, ${image_2x} 2x`} alt='food-bg'/>;
    }

    return (
        <div className='wrapped__carousel-slide wrapped__food-single'>
            <div className='wrapped__food-single__content'>
                <h1><FormattedHTMLMessage id="Wrapped2024.FoodSingle.ExisitsOnly"/></h1>
                <div>
                    <h1 className="singleLine">{capitalize(getLocalizedFoodName(data).toLowerCase())}</h1>
                    <p><FormattedHTMLMessage id="Wrapped2024.FoodSingle.EnjoiedIt" values={{count: data.count}}/></p>
                </div>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
            <div className="imageFrame">{renderImage()}</div>
        </div>
    );
}