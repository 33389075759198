import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/trailer_general/Haagised general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/trailer_general/Haagised general@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/trailer_general/_MG_0299-Recovered 1.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/trailer_general/_MG_0299-Recovered 1@2x.png";
import React from "react";

export const TrailerGeneral = ({data}) => {
    return (
        <div className='wrapped__carousel-slide wrapped__trailer-general'>
            <div className='wrapped__trailer-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.TrailerGeneral.Trailers"/></p>
                <h2><FormattedHTMLMessage
                    id="Wrapped2024.TrailerGeneral.MovedTogether"/><br/><span className="elephantsCount">{data.elephantsCount}</span><br/><FormattedHTMLMessage
                    id="Wrapped2024.TrailerGeneral.Elephants"/></h2>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
            <img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg' className='wrapped__trailer-general__background'/>
        </div>
    );
}