import { setAppVersion, setNotificationHubVariables, setUsingApp } from "../actions/appContainerActions";
import { trackEvent } from "../appInsights";
import Lang from "../lang/lang";

const baseUrl = window.location.hostname === "localhost" ? "https://testitk.alexela.ee" : window.location.origin;

const sendMessageToAppContainer = (type, data, delay = 0) => {
    const content = {
        type: type,
        data: data,
        language: Lang.getLang()
    };
    console.debug("Message to app container", content, JSON.stringify(content));

    if (!window.ReactNativeWebView) {
        console.error("ReactNativeWebView interface not found.");
        return;
    }

    trackEvent("messageToApp", { content: content, delay: delay });

    if (delay > 0) {
        setTimeout(() => postMessage(content), delay);
    } else {
        postMessage(content);
    }
};

const postMessage = (content) => {
    const message = JSON.stringify(content);
    console.debug("posting", message);
    window.ReactNativeWebView.postMessage(message);
}

const redirectToAppContainer = () => {
    sendMessageToAppContainer("route", "alexela/tangi-dashboard");
}

const redirectToAppContainerElectricChargerView = () => {
    sendMessageToAppContainer("route", "alexela/tangi-dashboard?filter=EV");
}

const redirectToAppContainerCylinderGasView = () => {
    sendMessageToAppContainer("route", "alexela/tangi-dashboard?filter=GK");
}

const redirectToAppContainerStationsView = () => {
    sendMessageToAppContainer("route", "alexela/tangi-dashboard?filter=FL");
}

const redirectToAppContainerElectricityContractsView = () => {
    sendMessageToAppContainer("route", { routeValue: "alexela/contracts/electricity" });
}

const redirectToAppContainerFuelPlusElectricityCampaignView = () => {
    sendMessageToAppContainer("route", { routeValue: "alexela/fuel-plus-electricity-campaign" });
}

const redirectToAppContainerHome = () => {
    sendMessageToAppContainer("route", { routeValue: 'alexela/home' });
}

const redirectToExternalUrl = (url) => {
    sendMessageToAppContainer('openUrl', { url: `${baseUrl}${url}` });
};

const shareDataToAppContainer = (title, fileOrUrl) => {
    sendMessageToAppContainer('share', { shareData: { title, file: fileOrUrl } });
};

const redirectToAppContainerSmsLogin = (phoneNumber) => {
    if (!phoneNumber) {
        return;
    }

    console.debug("re-routing to tangi app via SMS login");

    sendMessageToAppContainer("route", `alexela/tangi-dashboard?sms_user=${phoneNumber}`);
}

const sendLoginStatusToAppContainerWithDelay = (isLoggedIn, delay = 500) => {
    sendMessageToAppContainer("logininfo", { isLoggedIn: isLoggedIn }, delay);
}

const waitForWindowVariable = (store) => (windowVariableName, localStorageVariableName, storeSetAction, intervalMs = 500) => {
    const maxCount = 10 * 1000 / intervalMs; // run for 10 seconds

    let counter = 0;
    let intervalHandler = setInterval(() => {
        if (window.hasOwnProperty(windowVariableName)) {
            console.debug("variable found", windowVariableName);
            const value = window[windowVariableName];
            store.dispatch(storeSetAction(value, counter * intervalMs));
            console.debug("variable value dispatched to store");
            localStorage.setItem(localStorageVariableName, value);
            console.debug("variable value set in local storage");
            clearInterval(intervalHandler);
        } else if (counter > maxCount) {
            clearInterval(intervalHandler);
        }
        counter++;
    }, intervalMs);
}

const waitForWindowAppVariables = (store) => {
    waitForWindowVariable(store)("UsingApp", "usingapp", setUsingApp);
    waitForWindowVariable(store)("AppVersion", "appVersion", setAppVersion);
};

const waitForWindowNotificationHubVariables = (store) => waitForWindowVariable(store)("NotificationHub", "notificationHub", setNotificationHubVariables);

const isWebview = () => {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);

    return isWebview;
    //   const osText = isIos ? 'iOS' : isAndroid ? 'Android' : 'Other';
    // const webviewText = isWebview ? 'Yes' : 'No';
}

// https://stackoverflow.com/a/65687141
const isNewerOrSameAppVersion = (expected, current) => {
    if (!current) {
        return false;
    }

    return current.localeCompare(expected, undefined, { numeric: true, sensitivity: "base" }) > -1;
}

export {
    sendMessageToAppContainer,
    redirectToAppContainer,
    redirectToAppContainerCylinderGasView,
    redirectToAppContainerElectricChargerView,
    redirectToAppContainerStationsView,
    redirectToAppContainerElectricityContractsView,
    redirectToAppContainerFuelPlusElectricityCampaignView,
    redirectToAppContainerHome,
    sendLoginStatusToAppContainerWithDelay,
    redirectToAppContainerSmsLogin,
    redirectToExternalUrl,
    shareDataToAppContainer,
    waitForWindowAppVariables,
    waitForWindowNotificationHubVariables,
    isWebview,
    isNewerOrSameAppVersion,
    baseUrl,
};
