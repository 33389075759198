import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";

import { fetchPaymentCardInvoices } from "../../actions/invoiceActions";
import PaymentCardInvoicesFilter from "./PaymentCardInvoicesFilter";
import auth from "../../auth/authenticate";

import { getInvoiceDetailsPath, TransactionsPathWithPaymentCardInvoiceFilter } from "../../const/routes";
import {InvoiceType, Statuses} from "../../const/Invoice";

import { isInvoiceNotPaidAndDueDateExceeded } from "../../helpers/invoiceHelper";

import Loader from "../common/Loader";
import Pagination from "../common/Pagination";
import Alert from "../common/Alert";
import FilterButton from "../common/FilterButton";
import BaseFormComponent from "../common/BaseFormComponent";
import LinkElement from "../common/LinkElement";
import Currency from "../common/Currency";
import TableList from "../common/TableList";

import InvoiceStatus from "../Invoice/InvoiceStatus";
import exportToExcel from "../common/ExcelExport";

import { getInvoicesCardFilter, setInvoicesCardFilter } from "../../cachedFilter";

class PaymentCardInvoicesContainer extends BaseFormComponent {
    constructor(props) {
        super(props);

        this.fetchInvoices = this.fetchInvoices.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.renderTableRowJson = this.renderTableRowJson.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.scrollToRef = React.createRef();

        const cachedFilter = getInvoicesCardFilter();

        this.initialState = {
            selectedPage: 1,
            filterOpen: true,
            filter: {
                invoiceStartDate: moment().subtract(6, "months").startOf("month"),
                invoiceEndDate: moment(),
                deadlineStartDate: null,
                deadlineEndDate: null,
                invoiceNumber: cachedFilter.invoiceNumber,
                contractNumber: cachedFilter.contractNumber,
                contractName: cachedFilter.contractName,
                status: cachedFilter.status
            },
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.fetchInvoices(this.state.selectedPage, this.state.filter)
            .finally(this.props.didFinishInitialLoad);
    }

    get filteredInvoices() {
        return this.props?.invoices?.filter(i => i.type === this.props.type) ?? [];
    }

    onFilterSubmit(filter) {
        let page = 1;
        this.setState({ selectedPage: page, filter: filter });
        this.fetchInvoices(page, filter);
        setInvoicesCardFilter(filter);
    }

    fetchInvoices(page, filter) {
        return this.props.fetchPaymentCardInvoices(
            page,
            auth.getPageSize(),
            filter.invoiceStartDate.format("YYYY-MM-DD"),
            filter.invoiceEndDate.format("YYYY-MM-DD"),
            filter.deadlineStartDate?.format("YYYY-MM-DD"),
            filter.deadlineEndDate?.format("YYYY-MM-DD"),
            filter.invoiceNumber,
            filter.contractNumber,
            filter.contractName,
            filter.status
        );
    }

    canPayInvoice(invoice) {
        return invoice.status !== Statuses.StatusPaid &&
            invoice.status !== Statuses.StatusCredited &&
            invoice.status !== Statuses.StatusPaymentPending &&
            invoice.isLatestInvoice &&
            +invoice.subTotalWithVat > 0;
    }

    onPageChange({ page }) {
        this.setState({ selectedPage: page }, this.fetchInvoices.bind(this, page, this.state.filter));
    }

    groupInvoicesByMonth(invoices) {
        let groupedInvoices = {};
        for (let i in invoices) {
            let invoice = invoices[i];
            let groupKey = moment(invoice.periodEnd).format("YYYY-MM-01");

            if (!groupedInvoices[groupKey]) {
                groupedInvoices[groupKey] = {
                    invoices: [],
                    areAllPaid: true
                };
            }

            if (invoice.status !== Statuses.StatusPaid) {
                groupedInvoices[groupKey].areAllPaid = false;
            }

            groupedInvoices[groupKey].invoices.push(invoice);
        }

        return groupedInvoices;
    }

    exportToExcel() {
        const invoices = this.filteredInvoices.map(item => {
            item.invoiceDate = moment(item.invoiceDate).format("DD.MM.YYYY");
            item.deadline = moment(item.deadline).format("DD.MM.YYYY");
            item.status = this.props.intl.formatMessage({ id: `Invoice.Status.${item.status}` });
            if (!item.currency) { item.currency = "EUR"; }
            return item;
        });

        const columns = [
            {
                field: 'number',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.InvoiceNumber" }),
            },
            {
                field: 'invoiceDate',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.InvoiceDate" }),
            },
            {
                field: 'deadline',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.DueDate" }),
            },
            {
                field: 'status',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.Status" }),
            },
            {
                field: 'totalToBePaid',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.Total" }),
            },
            {
                field: 'currency',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.Currency" }),
            },
            {
                field: 'unpaid',
                title: this.props.intl.formatMessage({ id: "Invoices.Table.Unpaid" }),
            },
        ];

        exportToExcel(columns, invoices, "invoices.xlsx")
    }


    renderInvoicesHeader() {
        const showFilter = this.state.filterOpen;
        const hasItems = this.filteredInvoices.length > 0;

        return (
            <>
                <GridCell span={12}>
                    <>
                        <FilterButton
                            className="right mb-mobile-10"
                            active={showFilter}
                            callback={this.onChangeByField.bind(this, 'filterOpen')}
                        />
                        {hasItems &&
                            <Button outlined className="right hidden-mobile mr-mobile-up-10" onClick={this.exportToExcel}>
                                <i className="icon-download" />
                                <FormattedMessage id="Invoices.Export" />
                            </Button>}
                    </>
                </GridCell>
                <GridCell span={12}>
                    <PaymentCardInvoicesFilter
                        open={showFilter}
                        onSubmit={this.onFilterSubmit}
                        filter={this.state.filter}
                    />
                </GridCell>
                <GridCell span={12}>
                    {this.renderInvoicesMessages()}
                </GridCell>
            </>
        );
    }

    renderInvoicesMessages = () => {
        const invoicesNotFound = !this.props.areInvoicesLoading && this.props.areInvoicesFetched && (this.filteredInvoices.length < 1);

        return (invoicesNotFound
            ? <Alert title={<FormattedMessage id="Invoices.NotFound" />} />
            : <Alert title={<FormattedMessage id="Invoices.Messages.LoadingInvoicesDelayMessage" />} />
        )
    }

    renderInvoicesPeriods = invoices => {
        if (invoices.length < 1) {
            return null;
        }

        let grouped = this.groupInvoicesByMonth(invoices);
        let months = [];

        for (let key in grouped) {
            let invoices = grouped[key].invoices;

            months.push(
                this.renderInvoiceTable(key, invoices)
            );
        }

        return months;
    }

    renderTableRowJson(invoice) {
        const invoiceDeadline = invoice.deadline && moment(invoice.deadline);
        const invoiceNotPaidAndDueDateExceeded = isInvoiceNotPaidAndDueDateExceeded(invoice.status, invoice.deadline);

        const dateFormat = "DD.MM.YYYY";
        const invoiceDate = invoice.invoiceDate && moment(invoice.invoiceDate);

        const invoiceDetailsPath = getInvoiceDetailsPath(invoice.id, invoice.type);

        const result = {
            content: [
                {
                    content: <>
                        <InvoiceStatus status={invoice.status} deadline={invoice.deadline} showText={false} inline className="mr-12" />
                        <LinkElement href={invoiceDetailsPath} className="mdc-theme--primary">
                            <FormattedMessage id="Invoices.Table.InvoiceNumber" />
                            &nbsp;
                            {invoice.number}
                        </LinkElement>
                    </>
                },
                { content: invoiceDate && invoiceDate.format(dateFormat) },
                {
                    content: invoiceDeadline
                        ? <span className={invoiceNotPaidAndDueDateExceeded ? "mdc-theme--alert" : ""}>
                            {invoiceDeadline.format(dateFormat)}
                        </span>
                        : "-"
                },
                { content: <Currency value={invoice.totalToBePaid} /> },
                { content: <Currency value={invoice.unpaid} /> },
                { content: <InvoiceStatus status={invoice.status} deadline={invoice.deadline} showIcon={false} asLabel /> },
                {
                    content: <div className="d-flex justify-content-end">
                        <Button href={invoiceDetailsPath} tag="a" unelevated className="mdc-button--thin">
                            <FormattedMessage id={this.canPayInvoice(invoice)
                                ? "Invoices.Table.OpenInvoiceAndPay"
                                : "Invoices.Table.OpenInvoice"} />
                        </Button>
                    </div>
                }
            ]
        };

        // Add transactions link to payment card invoices only.
        if (this.props.type === InvoiceType.PaymentCard) {
            result.content.splice(6, 0, {
                content: <Link to={TransactionsPathWithPaymentCardInvoiceFilter(invoice.number)} >
                    <FormattedMessage id="Invoices.Table.ViewTransactions" />
                </Link>
            })
        }

        return result;
    }

    renderInvoiceTable(periodStart, invoices) {
        const periodTotal = invoices.map(x => x.sum).reduce((a, b) => a + b);

        const tableHeaders = [
            {
                label: moment(periodStart).format("MMMM YYYY").split('').map((x, i) => i === 0 ? x.toUpperCase() : x).join(''),
                colspan: 1
            },
            {
                label: "Invoices.Table.InvoiceDate",
                colspan: 1
            },
            {
                label: "Invoices.Table.DueDate",
                colspan: 1
            },
            {
                label: "Invoices.Table.Total",
                colspan: 1
            },
            {
                label: "Invoices.Table.Unpaid",
                colspan: 1
            },
            {
                label: "Invoices.Table.Status",
                colspan: 1
            },
            {
                label: <span className="text-italic">
                    <span className="mr-24">
                        <FormattedMessage id="Invoice.Table.Header.PeriodSum" defaultMessage="Perioodi summa" />:
                    </span>
                    <Currency value={periodTotal} />
                </span>,
                colspan: 2
            }
        ];
        const tableRowsJson = invoices.map(this.renderTableRowJson);

        return (
            <TableList
                key={"table-" + periodStart}
                headers={tableHeaders}
                itemContent={tableRowsJson} />
        );
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base mb-60 pt-10">
                {this.renderInvoicesHeader()}
                <GridCell span={12} ref={this.scrollToRef}>
                    {this.props.areInvoicesLoading
                        ? <Loader type={Loader.TYPE_CENTER} />
                        : this.renderInvoicesPeriods(this.filteredInvoices)}
                </GridCell>
                <GridCell span={12}>
                    <Pagination
                        total={this.props.totalInvoices || 0}
                        selectedPage={this.state.selectedPage}
                        onChange={this.onPageChange}
                        scrollToRef={this.scrollToRef}
                    />
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        totalInvoices: state.paymentCardInvoices.total,
        invoices: state.paymentCardInvoices.data,
        areInvoicesLoading: state.paymentCardInvoices.fetching,
        areInvoicesFetched: state.paymentCardInvoices.fetched
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaymentCardInvoices
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(PaymentCardInvoicesContainer)
);
