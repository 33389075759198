import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/renewable_energy_general/Taastuvenergia general.png";
import React from "react";

export const RenewableEnergyGeneral = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__renewable-energy-general'>
            <div className='wrapped__renewable-energy-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.RenewableEnergyGeneral.Growing" /></p>
                <p><FormattedHTMLMessage id="Wrapped2024.RenewableEnergyGeneral.Supply"/></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.RenewableEnergyGeneral.Paide"/></h1>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}