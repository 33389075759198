import React from "react";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Loader from "../components/common/Loader";
import { fetchAccount } from "../actions/accountActions";
import { fetchContracts } from "../actions/contractActions";
import { fetchConsumptionLocations } from "../actions/consumptionlocationActions";
import { fetchBonusCards, fetchPaymentCardStatus, updateUserGroupCode } from "../actions/cardActions";
import { fetchCustomerContribution } from "../actions/communityProjectActions";
import {
    CardsPath,
    ContractElectricityPath,
    ContractsPath,
    ContractGasPath,
    AgentGasPath,
    AgentElectricityPath,
    CommunityPath,
    PaymentCardsPath,
    ContractFuelPath,
    getCylinderGasContractOfferPath,
    getCardContractOfferPath,
    getEvChargingContractOfferPath
} from "../const/routes";
import auth from "../auth/authenticate";
import BaseDialog from "../components/common/BaseDialog";
import ContractJoinCard from "../components/cards/ContractJoinCard";
import BindCard from "../components/cards/BindCard";
import AlertMessage from "../components/common/AlertMessage";
import CommunityContribution from "../components/CommunityProject/CommunityContribution";
import UserContribution from "../components/CommunityProject/UserContribution";
import BonusCardUserGroupChange from "../components/cards/BonusCardUserGroupChange"
import { SendActionEvent } from "../helpers/GoogleTagHelper";
import BadgeCard from "../components/common/BadgeCard";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";
import {isBlank} from "../helpers/stringHelper";
import { Link, withRouter } from "react-router-dom";
import { CardApplicationType, PaymentCardStatus } from "../const/cardApplication";
import { fetchActiveOffers } from "../actions/offerActions";
import DebtNotice from "../components/common/DebtNotice";

import "../styles/blocks/_service.scss";
import "../styles/components/_badge-card.scss";

class ServiceEe extends React.Component {
    static typeBonusCard = "BonusCard";
    static typePaymentCard = "PaymentCard";
    static typeElectricity = "Electricity";
    static typeGas = "Gas";
    static typePaymentCardOffer = "PaymentCardOffer";
    static typeCylinderGasOffer = "CylinderGasOffer";
    static typeEvChargingOffer = "EvChargingOffer";

    static activeTypeNew = "new";
    static activeTypeDone = "done";
    static activeTypeNone = "none";
    static activeTypeInProgress = "inprogress";

    static serviceOrder = [
        ServiceEe.activeTypeInProgress,
        ServiceEe.activeTypeNew,
        ServiceEe.activeTypeDone,
        ServiceEe.activeTypeNone
    ];

    static serviceOrderFn = (svc1, svc2) => ServiceEe.serviceOrder.indexOf(svc1.activeType) - ServiceEe.serviceOrder.indexOf(svc2.activeType);

    static gas = "gas";
    static electricity = "electricity";

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            isAgent: auth.isAgent(),
            isCompany: auth.isCompany(),
            isLatvianWeb: IsLatvianWeb(),
            isBonusCardDialogOpen: false,
            isBindBonusCardDialogOpen: false,
            showOrderCardSuccess: false,
            showBindCardSuccess: false,
            isBonusCardUserGroupDialogOpen: false,
            showUserGroupChangeSuccess: false,
            isLoading: true
        };

        this.canNotLoadConsumptionLocations = this.canNotLoadConsumptionLocations.bind(this);
        this.canStartElmoContract = this.canStartElmoContract.bind(this);
        this.renderGasOrElectricityServiceSubtitle = this.renderGasOrElectricityServiceSubtitle.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.reloadServiceOrder = this.reloadServiceOrder.bind(this);
        this.renderCard = this.renderCard.bind(this);
        this.renderContribution = this.renderContribution.bind(this);
    }

    componentDidMount() {
        this.props.fetchAccount();
        if (!IsLatvianWeb()) {
            this.props.fetchBonusCards();
            this.props.fetchCustomerContribution();

            if (!this.state.isAgent) {
                this.props.fetchPaymentCardStatus();
                this.props.fetchActiveOffers();
            }
        }

        this.props.fetchConsumptionLocations()
            .finally(() => this.setState({ isLoading: false }));

        if (!this.props.contractsFetching && !this.contractsFetched) {
            this.props.fetchContracts();
        }

        this.reloadServiceOrder();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.consumptionLocations !== this.props.consumptionLocations
            || prevProps.bonusCards !== this.props.bonusCards
            || prevProps.paymentCardStatus !== this.props.paymentCardStatus) {
            this.reloadServiceOrder();
        }

        if (!this.props.bonusCardOrderFetching && prevProps.bonusCardOrderFetching && this.props.bonusCardOrderFetched) {
            this.setState({
                showOrderCardSuccess: true,
                isBonusCardDialogOpen: false
            });
        }
        if (!this.props.bonusCardBindFetching && prevProps.bonusCardBindFetching && this.props.bonusCardBindFetched) {
            this.setState({
                showBindCardSuccess: true,
                isBindBonusCardDialogOpen: false
            });
        }
        if (!this.props.isCardUserGroupChanging && prevProps.isCardUserGroupChanging && this.props.isCardUserGroupChanged) {
            this.setState({
                isBonusCardUserGroupDialogOpen: false,
                showUserGroupChangeSuccess: true
            });
        }
    }

    reloadServiceOrder() {
        let serviceArray = [];
        const showCards = !this.state.isLatvianWeb;

        const precontracts = (this.props.consumptionLocations && this.props.consumptionLocations.precontracts) || [];
        const locations = (this.props.consumptionLocations && this.props.consumptionLocations.locations) || [];

        let hasInProgressElectricity = !this.state.isLatvianWeb && precontracts.some(x => x.type === ServiceEe.electricity);
        let hasInProgressGas = precontracts.some(x => x.type === ServiceEe.gas);

        if (hasInProgressElectricity) {
            serviceArray.push({
                type: ServiceEe.typeElectricity,
                activeType: ServiceEe.activeTypeInProgress,
                highlighted: true,
            });
        }

        if (hasInProgressGas) {
            serviceArray.push({
                type: ServiceEe.typeGas,
                activeType: ServiceEe.activeTypeInProgress,
                highlighted: true
            });
        }

        let hasValidCard = showCards && this.props.bonusCards
            .filter(x => x.isPrimary)
            .length > 0;

        if (showCards && (!hasValidCard || this.state.isAgent)) {
            serviceArray.push({
                type: ServiceEe.typeBonusCard,
                activeType: ServiceEe.activeTypeNew,
                highlighted: true
            });
        }

        const paymentCardStatus = this.props.paymentCardStatus?.cardStatus;
        const offers = this.props.activeOffers;

        const isPaymentCardActive = showCards && paymentCardStatus &&
            (paymentCardStatus !== PaymentCardStatus.NONE &&
                paymentCardStatus !== PaymentCardStatus.REJECTED &&
                paymentCardStatus !== PaymentCardStatus.PENDING_DECISION);

        if (showCards && !isPaymentCardActive && !this.state.isAgent) {
            serviceArray.push({
                type: ServiceEe.typePaymentCard,
                activeType: ServiceEe.activeTypeNew,
                highlighted: true
            });
        }
        if (!this.state.isLatvianWeb && !hasInProgressElectricity && locations.some(x => x.type === ServiceEe.electricity && !x.isInContract)) {
            serviceArray.push({
                type: ServiceEe.typeElectricity,
                activeType: ServiceEe.activeTypeNew,
                highlighted: true
            });
        }

        if (!hasInProgressGas && locations.some(x => x.type === ServiceEe.gas && !x.isInContract)) {
            serviceArray.push({
                type: ServiceEe.typeGas,
                activeType: ServiceEe.activeTypeNew,
                highlighted: true
            });
        }

        if (hasValidCard) {
            serviceArray.push({
                type: ServiceEe.typeBonusCard,
                activeType: ServiceEe.activeTypeDone
            });
        }

        if (isPaymentCardActive && !this.state.isAgent) {
            serviceArray.push({
                type: ServiceEe.typePaymentCard,
                activeType: this.getPaymentCardActiveType(paymentCardStatus)
            });
        }

        if (!this.state.isLatvianWeb && locations.some(x => x.type === ServiceEe.electricity && x.isInContract)) {
            serviceArray.push({
                type: ServiceEe.typeElectricity,
                activeType: ServiceEe.activeTypeDone
            });
        }

        if (!this.state.isLatvianWeb && !locations.some(x => x.type === ServiceEe.electricity)) {
            serviceArray.push({
                type: ServiceEe.typeElectricity,
                activeType: ServiceEe.activeTypeNone
            });
        }

        if (locations.some(x => x.type === ServiceEe.gas && x.isInContract)) {
            serviceArray.push({
                type: ServiceEe.typeGas,
                activeType: ServiceEe.activeTypeDone
            });
        }

        if (!locations.some(x => x.type === ServiceEe.gas)) {
            serviceArray.push({
                type: ServiceEe.typeGas,
                activeType: ServiceEe.activeTypeNone
            });
        }

        if (showCards && offers.some(x => x.applicationType === CardApplicationType.OFFER)) {
            const cardOffers = offers.filter(x => x.applicationType === CardApplicationType.OFFER);

            cardOffers.forEach(offer => {
                serviceArray.push({
                    type: ServiceEe.typePaymentCardOffer,
                    activeType: ServiceEe.activeTypeInProgress,
                    id: offer.id
                });
            });
        }

        if (offers.some(x => x.applicationType === CardApplicationType.CYLINDER_GAS_OFFER)) {
            const cylinderGasOffers = offers.filter(x => x.applicationType === CardApplicationType.CYLINDER_GAS_OFFER);

            cylinderGasOffers.forEach(offer => {
                serviceArray.push({
                    type: ServiceEe.typeCylinderGasOffer,
                    activeType: ServiceEe.activeTypeInProgress,
                    id: offer.id
                });
            });
        }

        if (!this.state.isLatvianWeb && offers.some(x => x.applicationType === CardApplicationType.EV_CHARGING_OFFER)) {
            const evChargingOffers = offers.filter(x => x.applicationType === CardApplicationType.EV_CHARGING_OFFER);

            evChargingOffers.forEach(offer => {
                serviceArray.push({
                    type: ServiceEe.typeEvChargingOffer,
                    activeType: ServiceEe.activeTypeInProgress,
                    id: offer.id
                });
            });
        }

        this.setState({
            services: serviceArray
        });
    }

    canNotLoadConsumptionLocations() {
        return !this.props.consumptionLocationsFetched && !this.props.consumptionLocationsFetching;
    }

    canStartElmoContract() {
        return !this.state.isAgent
            || (auth.getRoleHasNoEmail()
                || (this.props.mainContactFetched && !isBlank(this.props.mainContact.email))
            );
    }

    renderLocation(location, index) {
        return (
            <div className="service-item__location" key={index} >
                <Typography use="body1" className="mdc-typography mdc-typography--body1--bold">{location.eicCode}</Typography>
                {location.address &&
                    <Typography use="body1" className="mdc-typography">{location.address}</Typography>}
            </div>
        );
    }

    renderCard(card, index) {
        const validTo = moment(card.validToDate);
        return (
            <div className="service-item__card" key={index}>
                <Typography use="body1" className="mdc-typography mdc-typography--body1--bold">{card.number}</Typography>
                {card.validToDate &&
                    <Typography use="body1" className="mdc-typography">
                        {this.props.intl.formatMessage({ id: "Service.Card.ValidTo" })} {validTo.format("DD.MM.YYYY")}
                    </Typography>
                }
            </div>
        );
    }

    renderRowItemCard(service) {
        const { bonusCards, userGroupCode } = this.props;
        const { isAgent } = this.state;
        const { activeType, highlighted } = service;

        return (
            <BadgeCard
                title={<FormattedMessage id="Service.Card" />}
                description={<FormattedMessage id="Service.Card.Description" />}
                subTitle={<>
                    {activeType === ServiceEe.activeTypeNew &&
                        <FormattedMessage id="Service.Card.None" />}
                </>}
                icon="card-2"
                type={activeType}
                highlighted={highlighted}
                contentChildren={<>
                    {activeType === ServiceEe.activeTypeDone &&
                        bonusCards
                            .filter(x => x.isPrimary)
                            .map(this.renderCard)
                    }
                </>}
                actions={<>
                    {activeType === ServiceEe.activeTypeDone && isAgent && this.props.userGroupCodeFetched && userGroupCode.userGroupCode &&
                        <Button onClick={this.openBonusCardUserGroupDialog.bind(this)}>
                            <FormattedMessage id="Service.UserGroup.Bind" />
                        </Button>
                    }
                    {activeType === ServiceEe.activeTypeDone && !isAgent &&
                        <Button
                            tag="a"
                            href={CardsPath}
                        >
                            <FormattedMessage id="Service.Card.Info" />
                        </Button>
                    }
                    {activeType === ServiceEe.activeTypeNew &&
                        <Button onClick={this.openBonusCardDialog.bind(this)} >
                            <FormattedMessage id="Service.Card.Order" />
                        </Button>
                    }
                </>}
            />
        );
    }

    renderRowItemPaymentCard(service) {
        const { paymentCardStatus, paymentCardStatusFetched } = this.props;
        const { activeType, highlighted } = service;
        const cardStatus = paymentCardStatus.cardStatus;

        return (
            <>
                {paymentCardStatusFetched &&
                    <BadgeCard
                        title={<FormattedMessage id="Service.PaymentCard" />}
                        description={<FormattedMessage id="Service.PaymentCard.Description" />}
                        subTitle={<>
                            {activeType === ServiceEe.activeTypeNew &&
                                <FormattedMessage id={this.state.isCompany && cardStatus === PaymentCardStatus.OK
                                    ? "Service.Card.Business"
                                    : "Service.Card.None"}
                                />
                            }
                        </>}
                        icon="card-2"
                        type={activeType}
                        highlighted={highlighted}
                        contentChildren={<>
                            {activeType === ServiceEe.activeTypeDone &&
                                this.renderCard(paymentCardStatus)
                            }
                        </>}
                        actions={<>
                            {(activeType === ServiceEe.activeTypeNew ||
                                paymentCardStatus.cardStatus === PaymentCardStatus.PENDING_SIGNATURE) &&
                                <Button
                                    tag={Link}
                                    to={ContractFuelPath}
                                >
                                    <FormattedMessage id={activeType === ServiceEe.activeTypeNew
                                        ? "Service.Card.Order"
                                        : "Service.Card.SignContract"}
                                    />
                                </Button>
                            }
                            {activeType === ServiceEe.activeTypeDone &&
                                <Button
                                    tag={Link}
                                    to={PaymentCardsPath}>
                                    <FormattedMessage id="Service.Card.Info" />
                                </Button>
                            }
                        </>}
                    />
                }
            </>);
    }

    renderGasOrElectricityServiceSubtitle(serviceType, activeType) {
        const canNotLoadConsumptionLocations = this.canNotLoadConsumptionLocations();
        const canNotStartElmoContract = !this.canStartElmoContract();

        if (canNotLoadConsumptionLocations) { return <FormattedMessage id="Service.ConsumptionLocationsError" />; }
        else if (canNotStartElmoContract) { return <FormattedMessage id="Service.ContactInfoMissingError" />; }
        else if (activeType === ServiceEe.activeTypeNone) { return <FormattedMessage id={`Service.${serviceType}.NoLocations`} />; }
        else if (activeType === ServiceEe.activeTypeInProgress) { return <FormattedMessage id={`Service.${serviceType}.InProgress`} />; }
    }

    renderRowItemElectricity(service) {
        const locations = this.props.consumptionLocations.locations || [];
        const { isAgent, isCompany } = this.state;
        const { activeType, highlighted } = service;
        const canNotLoadConsumptionLocations = this.canNotLoadConsumptionLocations();
        const canNotStartElmoContract = !this.canStartElmoContract();
        const isDisabled = canNotLoadConsumptionLocations || canNotStartElmoContract;

        return (
            isCompany && (activeType === ServiceEe.activeTypeNew || activeType === ServiceEe.activeTypeInProgress)  // active type new implies that consumptionlocations are loaded, because there is at least one consumption location that exists
                ? <BadgeCard
                    title={<FormattedMessage id="Service.Electricity" />}
                    icon="electricity"
                    type={activeType}
                    highlighted={highlighted}
                    description={<FormattedMessage id="Service.Electricity.ContactClientService" />}
                />
                : <BadgeCard
                    title={<FormattedMessage id="Service.Electricity" />}
                    description={<FormattedMessage id="Service.Electricity.Description" />}
                    subTitle={this.renderGasOrElectricityServiceSubtitle("Electricity", activeType)}
                    icon="electricity"
                    type={isDisabled ? BadgeCard.TYPE.DISABLED : activeType}
                    highlighted={highlighted}
                    contentChildren={
                        <>
                            {activeType === ServiceEe.activeTypeDone &&
                                locations
                                    .filter(x => x.type === ServiceEe.electricity && x.isInContract)
                                    .map(this.renderLocation)
                            }
                        </>}
                    actions={!isDisabled &&
                        <>
                            {activeType === ServiceEe.activeTypeNew &&
                                <Button tag="a" href={isAgent ? AgentElectricityPath : ContractElectricityPath} >
                                    <FormattedMessage id="Service.Contract.Create" />
                                </Button>
                            }
                            {activeType === ServiceEe.activeTypeDone && !isAgent &&
                                <Button tag="a" href={ContractsPath} >
                                    <FormattedMessage id="Service.Contract.Info" />
                                </Button>
                            }
                            {activeType === ServiceEe.activeTypeInProgress &&
                                <Button tag="a" href={isAgent ? AgentElectricityPath : ContractElectricityPath} >
                                    <FormattedMessage id="Service.Contract.Continue" />
                                </Button>
                            }
                        </>}
                />
        );
    }

    renderRowItemGas(service) {
        const locations = this.props.consumptionLocations.locations || [];
        const { isAgent, isCompany } = this.state;
        const { activeType, highlighted } = service;
        const canNotLoadConsumptionLocations = this.canNotLoadConsumptionLocations();
        const canNotStartElmoContract = !this.canStartElmoContract();
        const isDisabled = canNotLoadConsumptionLocations || canNotStartElmoContract;

        return (
            isCompany && (activeType === ServiceEe.activeTypeNew || activeType === ServiceEe.activeTypeInProgress) // active type new implies that consumptionlocations are loaded, because there is at least one consumption location that exists
                ? <BadgeCard
                    title={<FormattedMessage id="Service.Gas" />}
                    icon="gas"
                    type={activeType}
                    highlighted={highlighted}
                    description={<FormattedMessage id="Service.Gas.ContactClientService" />} />
                : <BadgeCard
                    title={<FormattedMessage id="Service.Gas" />}
                    subTitle={this.renderGasOrElectricityServiceSubtitle("Gas", activeType)}
                    description={<FormattedMessage id="Service.Gas.Description" />}
                    icon="gas"
                    type={isDisabled ? BadgeCard.TYPE.DISABLED : activeType}
                    highlighted={highlighted}
                    contentChildren={
                        <>
                            {activeType === ServiceEe.activeTypeDone &&
                                locations
                                    .filter(x => x.type === ServiceEe.gas && x.isInContract)
                                    .map(this.renderLocation)
                            }
                        </>}
                    actions={!isDisabled &&
                        <>
                            {activeType === ServiceEe.activeTypeNew &&
                                <Button tag="a" href={isAgent ? AgentGasPath : ContractGasPath} >
                                    <FormattedMessage id="Service.Contract.Create" />
                                </Button>
                            }
                            {activeType === ServiceEe.activeTypeDone && !isAgent &&
                                <Button tag="a" href={ContractsPath}>
                                    <FormattedMessage id="Service.Contract.Info" />
                                </Button>
                            }
                            {activeType === ServiceEe.activeTypeInProgress &&
                                <Button tag="a" href={isAgent ? AgentGasPath : ContractGasPath} >
                                    <FormattedMessage id="Service.Contract.Continue" />
                                </Button>
                            }
                        </>}
                />
        );
    }

    renderRowItemPaymentCardOffer(service) {
        return (
            <BadgeCard
                title={<FormattedMessage id="Service.Offer.PaymentCard" />}
                icon="card-2"
                type={service.activeType}
                highlighted={false}
                description={<FormattedMessage id="Service.Offer.PaymentCard.Description" />}
                actions={
                    <Button
                        tag={Link}
                        to={{
                            pathname: getCardContractOfferPath(service.id),
                            state: { applicationType: CardApplicationType.OFFER }
                        }}
                    >
                        <FormattedMessage id="Service.Card.SignContract" />
                    </Button>
                }
            />
        );
    }

    renderRowItemCylinderGasOffer(service) {
        return (
            <BadgeCard
                title={<FormattedMessage id="Service.Offer.CylinderGas" />}
                icon="gas"
                type={service.activeType}
                highlighted={false}
                description={<FormattedMessage id="Service.Offer.CylinderGas.Description" />}
                actions={
                    <Button
                        tag={Link}
                        to={{
                            pathname: getCylinderGasContractOfferPath(service.id),
                            state: { applicationType: CardApplicationType.CYLINDER_GAS_OFFER }
                        }}
                    >
                        <FormattedMessage id="Service.Card.SignContract" />
                    </Button>
                }
            />
        );
    }

    renderRowEvChargingOffer(service) {
        return (
            <BadgeCard
                title={<FormattedHTMLMessage id="Service.Offer.EvCharging2" />}
                icon="electricity"
                type={service.activeType}
                highlighted={false}
                description={<FormattedMessage id="Service.Offer.EvCharging.Description" />}
                actions={
                    <Button
                        tag={Link}
                        to={{
                            pathname: getEvChargingContractOfferPath(service.id)
                        }}
                    >
                        <FormattedMessage id="Service.Card.SignContract" />
                    </Button>
                }
            />
        );
    }

    renderRow(service, index) {
        return (
            <div key={index} className="service-item">
                {service.type === ServiceEe.typeBonusCard &&
                    this.renderRowItemCard(service)}

                {service.type === ServiceEe.typePaymentCard && !this.state.isAgent &&
                    this.renderRowItemPaymentCard(service)}

                {service.type === ServiceEe.typeElectricity &&
                    this.renderRowItemElectricity(service)}

                {service.type === ServiceEe.typeGas &&
                    this.renderRowItemGas(service)}

                {service.type === ServiceEe.typePaymentCardOffer &&
                    this.renderRowItemPaymentCardOffer(service)}

                {service.type === ServiceEe.typeCylinderGasOffer &&
                    this.renderRowItemCylinderGasOffer(service)}

                {service.type === ServiceEe.typeEvChargingOffer &&
                    this.renderRowEvChargingOffer(service)}
            </div>
        );
    }

    renderContribution() {
        const { screenWidth } = this.props;

        const isMobileOrTabletView = screenWidth < 992;

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12} className="mb-35">
                    {/* Following is a hopefully temporary hack to show contribution as cards on desktop and as badges on tablet/mobile */}
                    {isMobileOrTabletView && this.props.customerContributionFetched &&
                        (this.props.customerContribution.isActive
                            ? <UserContribution showLink={false} showInfo={false} />
                            : <CommunityContribution showLink={false} showInfo={false} />
                        )
                    }
                    {!isMobileOrTabletView && this.props.customerContributionFetched &&
                        (this.props.customerContribution.isActive
                            ? <UserContribution showLink={true} showInfo={true} />
                            : <CommunityContribution showLink={true} showInfo={true} />
                        )
                    }
                </GridCell>
                {isMobileOrTabletView &&
                    <GridCell span={12}>
                        <Button className="mdc-button--outlined mdc-button--secondary mdc-button--compact" tag="a" href={CommunityPath}>
                            <FormattedMessage id="Service.Community.Link" />
                        </Button>
                    </GridCell>}
            </Grid>
        );
    }

    renderBindBonusCardButton() {
        return (
            <Button
                className="mdc-button--secondary right mr-20 mr-mobile-clear"
                outlined
                onClick={this.openBindBonusCardDialog.bind(this)}
            >
                <FormattedMessage id="Service.BindBonusCard" />
            </Button>
        );
    }

    openBonusCardUserGroupDialog() {
        this.setState({
            isBonusCardUserGroupDialogOpen: true
        });
    }

    closeBonusCardUserGroupDialog() {
        this.setState({
            isBonusCardUserGroupDialogOpen: false
        });
    }

    openBonusCardDialog() {
        if (this.state.isBonusCardDialogOpen) {
            this.props.fetchBonusCards();
        } else {
            SendActionEvent("CardCreating");
        }
        this.setState({
            isBonusCardDialogOpen: true
        });
    }

    closeBonusCardDialog() {
        this.props.fetchBonusCards();
        this.setState({
            isBonusCardDialogOpen: false
        });
    }

    openBindBonusCardDialog() {
        if (this.state.isBindBonusCardDialogOpen) {
            this.props.fetchBonusCards();
        }
        this.setState({
            isBindBonusCardDialogOpen: true
        });
    }

    closeBindBonusCardDialog() {
        this.props.fetchBonusCards();
        this.setState({
            isBindBonusCardDialogOpen: false
        });
    }

    getPaymentCardActiveType(type) {
        switch (type) {
            case PaymentCardStatus.OK:
            case PaymentCardStatus.IN_PRODUCTION:
                return ServiceEe.activeTypeDone;
            case PaymentCardStatus.PENDING_SIGNATURE:
                return ServiceEe.activeTypeInProgress;
            case PaymentCardStatus.NONE:
            case PaymentCardStatus.REJECTED:
            case PaymentCardStatus.PENDING_DECISION:
                return ServiceEe.activeTypeNew;
            default:
                return ServiceEe.activeTypeDone;
        }
    }

    render() {
        let cardNumber;
        let bonuscardUserGroupCode;
        const { bonusCards, userGroupCode, account } = this.props;

        if (!!account.hasDebt) {
            return <DebtNotice/>;
        }

        const { services } = this.state;
        const highlightedServices = services.filter(x => x.highlighted).sort(ServiceEe.serviceOrderFn);
        const otherServices = services.filter(x => !x.highlighted).sort(ServiceEe.serviceOrderFn);

        if (this.props.bonusCardsFetched) {
            if (bonusCards.filter(x => x.isPrimary).length > 0) {
                cardNumber = Object.values(bonusCards).filter(x => x.isPrimary)[0].number;
            }
        }

        if (this.props.userGroupCodeFetched) {
            bonuscardUserGroupCode = userGroupCode.userGroupCode;
        }

        return (
            <>
                {this.state.isLoading && <Loader type={Loader.TYPE_CENTER} />}
                {!this.state.isLoading &&
                    <Grid className="mdc-layout-grid--base mb-60">
                        {this.state.showOrderCardSuccess &&
                            <GridCell span={12}>
                                <AlertMessage type={AlertMessage.TYPE_DONE}
                                    title={
                                        <FormattedMessage id={"Cards.Order.Success"} />
                                    } isSmall={true} />
                            </GridCell>
                        }
                        {this.state.showBindCardSuccess &&
                            <GridCell span={12}>
                                <AlertMessage type={AlertMessage.TYPE_DONE}
                                    title={
                                        <FormattedMessage id="Cards.BindBonusCard.Success" />
                                    } isSmall={true} />
                            </GridCell>
                        }
                        {this.state.isAgent && this.state.showUserGroupChangeSuccess && this.props.isCardUserGroupChanged &&
                            <GridCell span={12} >
                                <AlertMessage type={AlertMessage.TYPE_DONE}
                                    isSmall={true}
                                    scrollToMessage={true}
                                    title={
                                        <FormattedMessage id="Service.UserGroup.SuccessResponse" />
                                    } />
                            </GridCell>
                        }
                        {!this.state.isLatvianWeb && this.state.isAgent &&
                            <GridCell span={12}>
                                {this.renderBindBonusCardButton()}
                            </GridCell>
                        }
                        <GridCell span={12}>
                            {services.length > 0
                                ? <>
                                    {highlightedServices.map(this.renderRow)}
                                    {otherServices.length > 0 &&
                                        <div className="services-wrapper">
                                            {otherServices.map(this.renderRow)}
                                        </div>
                                    }
                                </>
                                :
                                <Loader type={Loader.TYPE_CENTER} />
                            }
                        </GridCell>
                    </Grid>
                }

                {!this.state.isLatvianWeb && this.renderContribution()}

                <BaseDialog
                    isOpen={this.state.isBonusCardDialogOpen}
                    onClose={this.closeBonusCardDialog.bind(this)}
                >
                    <ContractJoinCard />
                </BaseDialog>
                <BaseDialog
                    isOpen={this.state.isBindBonusCardDialogOpen}
                    onClose={this.closeBindBonusCardDialog.bind(this)}
                >
                    <BindCard userGroupCode={bonuscardUserGroupCode} />
                </BaseDialog>

                <BaseDialog
                    isOpen={this.state.isBonusCardUserGroupDialogOpen}
                    onClose={this.closeBonusCardUserGroupDialog.bind(this)}
                >
                    <BonusCardUserGroupChange cardNumber={cardNumber} userGroupCode={bonuscardUserGroupCode} />
                </BaseDialog>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.data,

        contracts: state.contracts.data,
        contractsFetched: state.contracts.fetched,
        contractsFetching: state.contracts.fetching,

        consumptionLocations: state.consumptionLocations.data,
        consumptionLocationsFetching: state.consumptionLocations.fetching,
        consumptionLocationsFetched: state.consumptionLocations.fetched,

        bonusCards: state.bonusCards.data,
        bonusCardsFetching: state.bonusCards.fetching,
        bonusCardsFetched: state.bonusCards.fetched,

        bonusCardOrderFetching: state.orderBonusCard.fetching,
        bonusCardOrderFetched: state.orderBonusCard.fetched,

        paymentCardStatus: state.paymentCardStatus.data,
        paymentCardStatusFetched: state.paymentCardStatus.fetched,

        customerContributionFetching: state.customerContribution.fetching,
        customerContributionFetched: state.customerContribution.fetched,
        customerContribution: state.customerContribution.data,

        bonusCardBindFetching: state.bindBonusCard.fetching,
        bonusCardBindFetched: state.bindBonusCard.fetched,

        userGroupCodeFetched: state.userGroupCode.fetched,
        userGroupCode: state.userGroupCode.data,

        isCardUserGroupChanged: state.updateUserGroupCode.fetched,
        isCardUserGroupChanging: state.updateUserGroupCode.fetching,

        screenWidth: state.ui.screenWidth,

        activeOffers: state.activeOffers.data,
        activeOffersFetching: state.activeOffers.fetching,
        activeOffersFetched: state.activeOffers.fetched,

        mainContact: state.mainContact.data,
        mainContactFetched: state.mainContact.fetched,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAccount,
        fetchContracts,
        fetchConsumptionLocations,
        fetchBonusCards,
        fetchCustomerContribution,
        updateUserGroupCode,
        fetchPaymentCardStatus,
        fetchActiveOffers
    }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(ServiceEe)));
