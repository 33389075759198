import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import moment from "moment";

import BaseFormComponent from "../components/common/BaseFormComponent";
import Tabs from "../components/common/Tabs";
import PaymentCardInvoicesContainer from "../components/invoices/PaymentCardInvoicesContainer";
import ElectricityInvoicesContainer from "../components/invoices/ElectricityInvoicesContainer";
import {IsLatvianWeb} from "../helpers/AlternativeDesign";
import {InvoiceType, Statuses} from "../const/Invoice";
import {fetchContracts} from "../actions/contractActions";
import AlertMessage from "../components/common/AlertMessage";
import {Link} from "react-router-dom";
import {ServicePath} from "../const/routes"
import * as ContractType from "../const/contractType";
import { LatvianElectricity } from "../const/featureFlags";
import { Feature } from "flagged";

class Invoices extends BaseFormComponent {

    static TabIndex = {Electricity: 0, PaymentCard: 1, Other: 2};

    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: Invoices.TabIndex.PaymentCard,
            electricityInvoicesLoaded: false,
            paymentCardInvoicesLoaded: false,
            isLatvianWeb: IsLatvianWeb(),
        };

        this.onElectricityInvoicesLoaded = this.onElectricityInvoicesLoaded.bind(this);
        this.onPaymentCardInvoicesLoaded = this.onPaymentCardInvoicesLoaded.bind(this);
        this.waitForBothInvoicesLoaded = this.waitForBothInvoicesLoaded.bind(this);
        this.getIndexOfTabToShow = this.getIndexOfTabToShow.bind(this);
    }

    componentDidMount() {
        if (!this.props.contractsFetching && !this.props.contractsFetched) {
            this.props.fetchContracts();
        }

        this.waitForBothInvoicesLoaded();
    }

    waitForBothInvoicesLoaded() {
        clearTimeout(this.timeout);
        if (this.state.electricityInvoicesLoaded && this.state.paymentCardInvoicesLoaded) {
            const tabIndex = this.getIndexOfTabToShow();
            this.setState({tabIndex: tabIndex});
        } else {
            this.timeout = setTimeout(this.waitForBothInvoicesLoaded, 100); /* this checks the flag every 100 milliseconds*/
        }
    }

    onElectricityInvoicesLoaded() {
        this.setState({electricityInvoicesLoaded: true});
    }

    onPaymentCardInvoicesLoaded() {
        this.setState({paymentCardInvoicesLoaded: true});
    }

    sortByInvoiceDate(a, b) {
        return moment(a.invoiceDate).isBefore(b.invoiceDate);
    }

    get unpaidElectricityInvoices() {
        const {electricityInvoices} = this.props;

        return electricityInvoices && electricityInvoices.length > 0
            ? electricityInvoices
                .filter(x => x.status === Statuses.StatusUnpaid)
                .sort(this.sortByInvoiceDate)
                .map(x => ({id: x.id, invoiceDate: x.invoiceDate}))
            : [];
    }

    unpaidPaymentCardInvoices(type) {
        const {paymentCardInvoices} = this.props;

        return paymentCardInvoices && paymentCardInvoices.length > 0
            ? paymentCardInvoices
                .filter(x => x.status === Statuses.StatusUnpaid && x.type === type)
                .sort(this.sortByInvoiceDate)
                .map(x => ({id: x.id, invoiceDate: x.invoiceDate}))
            : [];
    }

    get unpaidPaymentCardFuelInvoices() {
        return this.unpaidPaymentCardInvoices(InvoiceType.PaymentCard);
    }

    get unpaidPaymentCardOtherInvoices() {
        return this.unpaidPaymentCardInvoices(InvoiceType.EvCharging);
    }

    getIndexOfTabToShow() {
        const {paymentCardInvoices, electricityInvoices} = this.props;

        const actualPaymentCardInvoices = paymentCardInvoices.filter(i => i.type === InvoiceType.PaymentCard);
        const evChargingInvoices = paymentCardInvoices.filter(i => i.type === InvoiceType.EvCharging);

        const unpaidElectricityInvoices = this.unpaidElectricityInvoices;
        const unpaidPaymentCardInvoices = this.unpaidPaymentCardFuelInvoices;
        const unpaidOtherInvoices = this.unpaidPaymentCardOtherInvoices;

        const anyElectricity = electricityInvoices && electricityInvoices.length > 0;
        const anyPaymentCard = actualPaymentCardInvoices && actualPaymentCardInvoices.length > 0;
        const anyOther = evChargingInvoices && evChargingInvoices.length > 0;

        const anyUnpaidElectricity = unpaidElectricityInvoices.length > 0;
        const anyUnpaidPaymentCard = unpaidPaymentCardInvoices.length > 0;
        const anyUnpaidOther = unpaidOtherInvoices.length > 0;

        if (anyUnpaidElectricity || anyUnpaidPaymentCard || anyUnpaidOther) {
            const electricityInvoiceDate = moment(anyUnpaidElectricity ? unpaidElectricityInvoices[0].invoiceDate : 0);
            const paymentCardInvoiceDate = moment(anyUnpaidPaymentCard ? unpaidPaymentCardInvoices[0].invoiceDate : 0);
            const otherInvoiceDate = moment(anyUnpaidOther ? unpaidOtherInvoices[0].invoiceDate : 0);

            console.log(electricityInvoiceDate);
            if (electricityInvoiceDate.isAfter(paymentCardInvoiceDate) && electricityInvoiceDate.isAfter(otherInvoiceDate)) {
                return Invoices.TabIndex.Electricity;
            }

            if (paymentCardInvoiceDate.isAfter(electricityInvoiceDate) && paymentCardInvoiceDate.isAfter(otherInvoiceDate)) {
                return Invoices.TabIndex.PaymentCard;
            }

            if (otherInvoiceDate.isAfter(electricityInvoiceDate) && otherInvoiceDate.isAfter(paymentCardInvoiceDate)) {
                return Invoices.TabIndex.Other;
            }
        }

        if (anyElectricity) {
            return Invoices.TabIndex.Electricity;
        }

        if (anyPaymentCard) {
            return Invoices.TabIndex.PaymentCard;
        }

        if (anyOther) {
            return Invoices.TabIndex.Other;
        }

        return this.state.initialTabIndex;
    }

    render() {
        const hasFuelContracts = this.props.contracts.find(c => c.type === ContractType.Fuel) !== undefined;

        const hasOtherInvoices = this.props.contracts.find(c => c.type === ContractType.EvCharging) !== undefined
            || this.props.paymentCardInvoices.find(i => i.type === InvoiceType.EvCharging) !== undefined;

        const defaultTabIndex = hasFuelContracts && !this.state.isLatvianWeb ? this.state.tabIndex : Invoices.TabIndex.Electricity;
        const tabItems = [{
            label: (
                <Feature name={LatvianElectricity}>
                    {(isEnabled) =>
                        <FormattedMessage id={isEnabled ? "Invoices.InvoiceType.ElectricityAndGas" : "Invoices.InvoiceType.Gas"} />
                    }
                </Feature>
            ),
            contents: <ElectricityInvoicesContainer didFinishInitialLoad={this.onElectricityInvoicesLoaded} />,
            icon: this.unpaidElectricityInvoices.length > 0 && { icon: "alert-small", className: "mdc-theme--alert" }
        }];

        if (!this.state.isLatvianWeb) {
            if (hasFuelContracts) {
                tabItems.push({
                    label: <FormattedMessage id="Invoices.InvoiceType.PaymentCard"/>,
                    contents: <PaymentCardInvoicesContainer didFinishInitialLoad={this.onPaymentCardInvoicesLoaded}
                                                            type={InvoiceType.PaymentCard}/>,
                    icon: this.unpaidPaymentCardFuelInvoices.length > 0 && {
                        icon: "alert-small",
                        className: "mdc-theme--alert"
                    }
                });
            } else {
                tabItems.push({
                    label: <FormattedMessage id="Invoices.InvoiceType.PaymentCard"/>,
                    contents:
                        <AlertMessage
                            className="mt-40"
                            type={AlertMessage.TYPE_NOTICE}
                            title={
                                <FormattedMessage
                                    id="Invoices.PaymentCard.NoContract"
                                    values={{
                                        link:
                                            <Link to={ServicePath}>
                                                <FormattedMessage id="Invoices.PaymentCard.NoContract.LinkText"/>
                                            </Link>
                                    }}
                                />
                            }
                        />
                });
            }

            if (hasOtherInvoices) {
                tabItems.push({
                    label: <FormattedMessage id="Invoices.InvoiceType.Other"/>,
                    contents: <PaymentCardInvoicesContainer didFinishInitialLoad={this.onPaymentCardInvoicesLoaded}
                                                            type={InvoiceType.EvCharging}/>,
                    icon: this.unpaidPaymentCardOtherInvoices.length > 0 && {
                        icon: "alert-small",
                        className: "mdc-theme--alert"
                    }
                });
            }
        }

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12}>
                    <Tabs
                        items={tabItems}
                        defaultTabIndex={defaultTabIndex}
                    />
                </GridCell>
            </Grid>
        );
    }

}

function mapStateToProps(state) {
    return {
        paymentCardInvoicesFetched: state.paymentCardInvoices.fetched,
        paymentCardInvoicesFetching: state.paymentCardInvoices.fetching,
        paymentCardInvoices: state.paymentCardInvoices.data,
        electricityInvoicesFetched: state.electricityInvoices.fetched,
        electricityInvoicesFetching: state.electricityInvoices.fetching,
        electricityInvoices: state.electricityInvoices.data,
        contracts: state.contracts.data,
        contractsFetching: state.contracts.fetching,
        contractsFetched: state.contracts.fetched
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchContracts
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Invoices);
