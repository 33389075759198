import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/community_member/Kogukond liige.png";
import React from "react";

export const CommunityMember = ({data}) => {
    return (
        <div className='wrapped__carousel-slide wrapped__community-member'>
            <div className='wrapped__community-member__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.CommunityMember.ThankYou" /></p>
                <h1>{data.treesPlanted}</h1>
                <h1><FormattedHTMLMessage id="Wrapped2024.CommunityMember.LittleTrees"/></h1>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}