import { WRAPPED_INFO } from '../../actions/campaignActions';

export default function reducer(state = {
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${WRAPPED_INFO}_PENDING`: {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case `${WRAPPED_INFO}_REJECTED`: {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case `${WRAPPED_INFO}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}