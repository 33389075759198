import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/renewable_energy_rohesarts/Taastuvenergia rohesärts.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/renewable_energy_rohesarts/Taastuvenergia rohesärts@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/renewable_energy_rohesarts/Taastuvenergia rohesärts 2.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/renewable_energy_rohesarts/Taastuvenergia rohesärts 2@2x.png";
import React from "react";

export const RenewableEnergyRohesarts = ({data}) => {
    return (
        <div className='wrapped__carousel-slide wrapped__renewable-energy-rohesarts'>
            <div className='wrapped__renewable-energy-rohesarts__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.RenewableEnergyRohesarts.Contribution"/></p>
                <h1>{data.greenEnergyRate}<br/><FormattedHTMLMessage id="Wrapped2024.RenewableEnergyRohesarts.GreenEnergy"/></h1>
            </div>

            <div className="imageFrame"><img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__renewable-energy-rohesarts__background'/></div>
            <img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__foreground'/>
        </div>
    );
}