import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@rmwc/button';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContractElectricityPath } from '../../const/routes';
import {useDispatch, useSelector} from 'react-redux';
import { fetchConsumptionLocations } from '../../actions/consumptionlocationActions';
import AlertMessage from '../common/AlertMessage';
import Loader from '../common/Loader';

export const RedirectToFuelPlusElectricity = "redirectToFuelPlusElectricity";

export const FuelPlusElectricitySign = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const redirect = () => {
        localStorage.setItem(RedirectToFuelPlusElectricity, true);
        history.push(ContractElectricityPath);
    }
    const { data, fetching } = useSelector(state => state.consumptionLocations);

    const hasConsumptionLocations = data?.locations?.length > 0;

    useEffect(() => {
        dispatch(fetchConsumptionLocations());
    }, [dispatch]);

    if (fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    return (
        <Grid>
            <GridCell desktop={6} tablet={4}>
                <Typography use="headline3" className="mdc-typography mdc-theme--primary" >
                    <FormattedMessage id="FuelPlusElectricity.Sign.Title"/>
                </Typography>
                <Typography use="body1" className="mdc-typography mt-20">
                    <FormattedHTMLMessage id="FuelPlusElectricity.Sign.Text"/>
                </Typography>
                <Button
                    className="mt-50"
                    unelevated
                    trailingIcon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                    }}
                    onClick={redirect}
                    disabled={!hasConsumptionLocations}
                >
                    <FormattedMessage id="FuelPlusElectricity.Sign.Redirect"/>
                </Button>
            </GridCell>
            <GridCell desktop={6} tablet={4}>
                <div className="fuel-plus-electricity-banner fuel-plus-electricity-banner--fuelling"></div>
            </GridCell>
            {!hasConsumptionLocations &&
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={intl.formatMessage({ id: 'FuelPlusElectricity.Sign.NoConsumptionLocations' })}
                    />
                </GridCell>
            }
        </Grid>
    )
}