import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/ev_charging_general/Elektrilaadija general.png";
import React from "react";

export const EvChargingGeneral = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__ev-charging-general'>
            <div className='wrapped__ev-charging-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.EvChargingGeneral.Title"/></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.EvChargingGeneral.Chargers"/></h1>
            </div>

            <img src={slideBg} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}