import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/smart_electricity_general/Tark elekter general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/smart_electricity_general/Tark elekter general@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/smart_electricity_general/Tark elekter general 2.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/smart_electricity_general/Tark elekter general 2@2x.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const SmartElectricityGeneral = ({data}) => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__smart-electricity-general'>
            <div className='wrapped__smart-electricity-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.SmartElectricityGeneral.SmartElectricity"/></p>
                <div>
                    <p><FormattedHTMLMessage id="Wrapped2024.SmartElectricityGeneral.Earnings"/></p>
                    <h1>{toDecimalString(data.totalProfit, intl.locale)} €</h1>
                </div>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
            <div className="imageFrame"><img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg'
                                             className='wrapped__smart-electricity-general__background'/></div>
        </div>
    );
}