import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import classNames from "classnames";
import { GridCell, GridInner } from "@rmwc/grid";
import ProductType from "../../const/productType";
import auth from "../../auth/authenticate";
import { Button } from "@rmwc/button";

import "../../styles/components/_contract-info.scss";
import { getElectricityContractRenewalPath } from "../../const/routes";
import { useHistory, useParams } from "react-router-dom";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";

const ContractInfoRow = ({ contract, location }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isCompany = auth.isCompany();
    const intl = useIntl();
    const history = useHistory();
    const { id } = useParams();

    const showElectricityPriceRow = location.product.productType === ProductType.FixedDegrade ||
        (location.product.priceDay != null && location.product.priceNight != null) ||
        (IsLatvianWeb() && location.product.priceDay != null);

    const showGasPriceRow = location.product.gasPrice != null;
    const contractStart = location.product.eleringStartMin ?? contract.start;
    const contractEnd = location.product.eleringEndMax ?? contract.end;

    function onRenewClick() {
        history.push(getElectricityContractRenewalPath(id));
    }

    return (
        <GridInner className="contract-info">
            <GridCell span={12} className="contract-info--item" onClick={() => setIsOpen(!isOpen)}>
                <div className="text-bold">
                    {`(${location.eicCode}) ${location.address}`}
                </div>
                <div>
                    <i className={classNames("icon icon-angle-down p-0", isOpen ? "rotate" : "")} />
                </div>
            </GridCell>
            {isOpen &&
                <>
                    <GridCell span={6} className="contract-info--item--child title">
                        <FormattedMessage id="ContractDetails.Table.ProductNameTitle" />
                    </GridCell>
                    <GridCell span={6} className="contract-info--item--child">
                        {location.product.productName}
                    </GridCell>

                    {showElectricityPriceRow &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.ElectricityPriceTitle" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {location.product.productType === ProductType.FixedDegrade
                                    ? <FormattedMessage id="ContractDetails.Table.ElectricityPriceWithPdf" />
                                    : <FormattedMessage id="ContractDetails.Table.ElectricityPrice"
                                        values={{
                                            dayPrice: location.product.priceDay.value,
                                            nightPrice: location.product?.priceNight?.value
                                        }}
                                    />
                                }
                            </GridCell>
                        </>
                    }

                    {showGasPriceRow &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id={isCompany
                                    ? "ContractDetails.Table.GasPriceTitleCompany"
                                    : "ContractDetails.Table.GasPriceTitle"}
                                />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {location.product.productType === ProductType.DynamicPrice
                                    ? <a href={intl.formatMessage({ id: "ContractDetails.Table.DynamicPriceUrl" })}
                                        target="_blank"
                                        rel="noreferrer">
                                        {location.product.gasPrice.value}
                                    </a>
                                    : <span>{location.product.gasPrice.value} {location.product.gasPrice.unit}</span>
                                }
                            </GridCell>
                        </>
                    }

                    {location.product.deposit != null &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.Deposit" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {location.product.deposit.value} {location.product.deposit.unit}
                            </GridCell>
                        </>
                    }

                    {location.product.basePrice != null &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.BasePrice" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {location.product.basePrice.value} {location.product.basePrice.unit}
                            </GridCell>
                        </>
                    }

                    {location.product.stockMarginal != null &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.MarginalTitle" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {`${location.product.stockMarginal.value} ${location.product.stockMarginal.unit}`}
                            </GridCell>
                        </>
                    }

                    {location.product.monthlyFee != null &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.MonthlyFeeTitle" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                {location.product.monthlyFee.value} {location.product.monthlyFee.unit}
                            </GridCell>
                        </>
                    }

                    <GridCell span={6} className="contract-info--item--child title">
                        <FormattedMessage id="ContractDetails.Table.SettlementPeriodTitle" />
                    </GridCell>
                    <GridCell span={6} className="contract-info--item--child">
                        {location.product.settlementPeriod}
                    </GridCell>

                    {location.product.productType !== ProductType.UniversalPrice && location.product.productType !== ProductType.UniversalDynamicPrice &&
                        <>
                            <GridCell span={6} className="contract-info--item--child title">
                                <FormattedMessage id="ContractDetails.Table.PricePeriodDurationTitle" />
                            </GridCell>
                            <GridCell span={6} className="contract-info--item--child">
                                <FormattedMessage
                                    id={location.product.pricePeriodDuration === null || location.product.pricePeriodDuration < 0
                                        ? "ContractDetails.Table.PricePeriodInfiniteDuration"
                                        : "ContractDetails.Table.PricePeriodDuration"
                                    }
                                    values={{
                                        period: location.product.pricePeriodDuration,
                                        startDate: moment(location.product.pricePeriodStart).format("DD.MM.YYYY")
                                    }}
                                />
                            </GridCell>
                        </>
                    }

                    <GridCell span={6} className="contract-info--item--child title">
                        <FormattedMessage id="ContractDetails.Table.ContractStartTitle2" />
                    </GridCell>
                    <GridCell span={6} className="contract-info--item--child">
                        {moment(contractStart).format("DD.MM.YYYY")}
                    </GridCell>

                    <GridCell span={6} className="contract-info--item--child title">
                        <FormattedMessage id="ContractDetails.Table.ContractEndTitle" />
                    </GridCell>
                    <GridCell span={6} className="contract-info--item--child">
                        {contractEnd != null
                            ?
                            <>
                                {moment(contractEnd).format("DD.MM.YYYY")}
                                {contract.canExtend &&
                                    <Button className="mdc-button--thin ml-24 ml-mobile-clear mt-mobile-16"
                                        unelevated
                                        tag="a"
                                        onClick={onRenewClick}
                                    >
                                        <FormattedMessage id="ContractDetails.Table.ExtendContract" />
                                    </Button>
                                }
                            </>
                            : <FormattedMessage id="ContractDetails.Table.Infinite" />
                        }
                    </GridCell>
                </>
            }
        </GridInner>
    )
}

export default ContractInfoRow;
