import {FormattedHTMLMessage, useIntl} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/loyalty_personal/Lojaalsus personal 10 pluss.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/loyalty_personal/Lojaalsus personal 10 pluss@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/loyalty_personal/Lojaalsus personal 10 pluss 2.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/loyalty_personal/Lojaalsus personal 10 pluss 2@2x.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const LoyaltyPersonal = ({data}) => {
    const intl = useIntl();
    const subtextId = data.unusedMoney < 9
        ? "Wrapped2024.LoyaltyPersonal.SubtextFew"
        : data.unusedMoney < 10
            ? "Wrapped2024.LoyaltyPersonal.SubtextAlmost10"
            : "Wrapped2024.LoyaltyPersonal.SubtextMoreThan10";

    return (
        <div className='wrapped__carousel-slide wrapped__loyalty-personal'>
            <div className='wrapped__loyalty-personal__content'>
                <h1><FormattedHTMLMessage id="Wrapped2024.LoyaltyPersonal.YouStillhave"/></h1>
                <div>
                    <h1><FormattedHTMLMessage id="Wrapped2024.LoyaltyPersonal.UnusedMoney"
                                              values={{amount: toDecimalString(data.unusedMoney, intl.locale, 2)}}/>
                    </h1>
                    <p><FormattedHTMLMessage id={subtextId}/></p>
                </div>
            </div>

            <div className="imageFrame">
                <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                     className='wrapped__loyalty-personal__background'/>
            </div>
            <img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__foreground'/>
        </div>
    );
}