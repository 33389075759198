import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import slideBg from '../../../images/wrapped_2024/slides/electricity_general/electricity-general.png';
import slideBg_2x from '../../../images/wrapped_2024/slides/electricity_general/electricity-general@x2.png';
import properLevel from '../../../images/wrapped_2024/slides/electricity_general/proper-level.png';
import properLevel_2x from '../../../images/wrapped_2024/slides/electricity_general/proper-level@x2.png';

const ElectricityGeneral = ({data}) => {
  const intl = useIntl();
  return (
    <div className='wrapped__carousel-slide wrapped__el-general'>
      <div className='wrapped__el-general__content'>
        <p>
          <FormattedHTMLMessage
            id='Wrapped2024.ElectricityGeneral.NewClients'
            values={{newClients: data.newClients.toLocaleString(intl.locale)}}
          />
        </p>
        <img
          src={properLevel}
          srcSet={`${properLevel} 1x, ${properLevel_2x} 2x`}
          alt='proper-level'
          className='wrapped__el-general__proper-level'
        />
        <h1>
          <FormattedHTMLMessage
            id='Wrapped2024.ElectricityGeneral.Consumed'
            values={{mDays: Math.round(data.daysOfSpark)}}
          />
        </h1>
      </div>

      <img
        src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`}
        alt='wave-bg-teal'
        className='wrapped__carousel-slide__background'
      />
    </div>
  );
};

export default ElectricityGeneral;
