import {FormattedHTMLMessage} from "react-intl";
import shortSlideBg from "../../../images/wrapped_2024/slides/trailer_personal/Haagised short rent.png";
import shortSlideBg_2x from "../../../images/wrapped_2024/slides/trailer_personal/Haagised short rent@2x.png";
import shortSlideFg from "../../../images/wrapped_2024/slides/trailer_personal/Haagised short rent 2.png";
import shortSlideFg_2x from "../../../images/wrapped_2024/slides/trailer_personal/Haagised short rent 2@2x.png";
import longSlideBg from "../../../images/wrapped_2024/slides/trailer_personal/Haagised long rent.png";
import longSlideBg_2x from "../../../images/wrapped_2024/slides/trailer_personal/Haagised long rent@2x.png";
import longSlideFg from "../../../images/wrapped_2024/slides/trailer_personal/Haagised long rent 2.png";
import longSlideFg_2x from "../../../images/wrapped_2024/slides/trailer_personal/Haagised long rent 2@2x.png";
import React from "react";

export const TrailerPersonal = ({type, data}) => {
    const renderLongBg = () => {
        return (
            <>
                <div className="imageFrame">
                    <img src={longSlideBg} srcSet={`${longSlideBg} 1x, ${longSlideBg_2x} 2x`} alt='slide-bg'
                         className='wrapped__trailer-personal__background'/>
                </div>
                <img src={longSlideFg} srcSet={`${longSlideFg} 1x, ${longSlideFg_2x} 2x`} alt='slide-bg'
                     className='wrapped__carousel-slide__foreground'/>
            </>
        )
    }
    const renderShortBg = () => {
        return <>
            <div className="imageFrame">
                <img src={shortSlideBg} srcSet={`${shortSlideBg} 1x, ${shortSlideBg_2x} 2x`} alt='slide-bg'
                     className='wrapped__trailer-personal__background'/>
            </div>
            <img src={shortSlideFg} srcSet={`${shortSlideFg} 1x, ${shortSlideFg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__foreground'/>
        </>
    }

    const titleId = type === "long" ? "Wrapped2024.TrailerPersonal.LongTitle" : "Wrapped2024.TrailerPersonal.ShortTitle";
    return (
        <div className='wrapped__carousel-slide wrapped__trailer-personal'>
            <div className='wrapped__trailer-personal__content'>
                <p><FormattedHTMLMessage id={titleId}/></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.TrailerPersonal.RentTime"
                                          values={{hours: data.rentTimeHours, minutes: data.rentTimeMinutes}}/></h1>
            </div>

            {type === "long" ? renderLongBg() : renderShortBg()}
        </div>
    );
}