import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import { updateIntl } from "react-intl-redux";
import {Grid, GridCell} from '@rmwc/grid';
import {Button} from '@rmwc/button';
import {withFeature} from 'flagged';
import {isMobile} from 'react-device-detect';
import classNames from 'classnames';
import {Wrapped2024} from '../const/featureFlags';
import Carousel from '../components/Carousel/Carousel';
import Intro from '../components/Wrapped/Intro';
import ElectricityGeneral from '../components/Wrapped/Slides/ElectricityGeneral';
import ElectricityPersonalFix from '../components/Wrapped/Slides/ElectricityPersonalFix';

import {useDispatch, useSelector} from "react-redux";
import {getFuelPlusElectricityInfo, getWrappedInfo} from "../actions/campaignActions";
import {ElectricityPersonalPingevaba} from "../components/Wrapped/Slides/ElectricityPersonalPingevaba";
import {ElectricityPersonalBors} from "../components/Wrapped/Slides/ElectricityPersonalBors";
import {GasGeneral} from "../components/Wrapped/Slides/GasGeneral";
import {GasPersonal} from "../components/Wrapped/Slides/GasPersonal";
import {FoodGeneral} from "../components/Wrapped/Slides/FoodGeneral";
import {FoodTop3} from "../components/Wrapped/Slides/FoodTop3";
import {FoodSingle} from "../components/Wrapped/Slides/FoodSingle";
import {DrinkGeneral} from "../components/Wrapped/Slides/DrinkGeneral";
import {DrinkRefillable} from "../components/Wrapped/Slides/DrinkRefillable";
import {CylinderGasGeneral} from "../components/Wrapped/Slides/CylinderGasGeneral";
import {CylinderGasPersonal} from "../components/Wrapped/Slides/CylinderGasPersonal";
import {FuelGeneral} from "../components/Wrapped/Slides/FuelGeneral";
import {FuelPersonal} from "../components/Wrapped/Slides/FuelPersonal";
import {EvChargingGeneral} from "../components/Wrapped/Slides/EvChargingGeneral";
import {EvChargingPersonal} from "../components/Wrapped/Slides/EvChargingPersonal";
import {CommunityGeneral} from "../components/Wrapped/Slides/CommunityGeneral";
import {CommunityDay} from "../components/Wrapped/Slides/CommunityDay";
import {CommunityMember} from "../components/Wrapped/Slides/CommunityMember";
import {RenewableEnergyGeneral} from "../components/Wrapped/Slides/RenewableEnergyGeneral";
import {RenewableEnergyRohesarts} from "../components/Wrapped/Slides/RenewableEnergyRohesarts";
import {SmartElectricityGeneral} from "../components/Wrapped/Slides/SmartElectricityGeneral";
import {TrailerGeneral} from "../components/Wrapped/Slides/TrailerGeneral";
import {TrailerPersonal} from "../components/Wrapped/Slides/TrailerPersonal";
import {Loyalty} from "../components/Wrapped/Slides/Loyalty";
import {LoyaltyPersonal} from "../components/Wrapped/Slides/LoyaltyPersonal";
import {DrinkDisposable} from "../components/Wrapped/Slides/DrinkDisposable";
import {UsingApp} from "../helpers/AlternativeDesign";
import {baseUrl, redirectToAppContainerHome, shareDataToAppContainer} from '../helpers/appContainerHelper';
import language from "../lang/lang";

import '../styles/react-components/wrapped.scss';
import {Outro} from "../components/Wrapped/Outro";

const Wrapped = () => {
    const dispatch = useDispatch();
    const {data, fetching, error} = useSelector(state => state.wrappedInfo)
    const {data: campaignInfo, fetching: campaignInfoFetching, error: campaignInfoError} = useSelector(state => state.fuelPlusElectricityInfo)
    const [showWrappedInfo, setShowWrappedInfo] = React.useState(true);
    const intl = useIntl();

    useEffect(() => {
        if (UsingApp() && window.locale) {
          dispatch(updateIntl({
            locale: window.locale,
            messages: language.getMessages(window.locale)
          }));
          language.setLang(window.locale);
        }
        dispatch(getWrappedInfo())
        dispatch(getFuelPlusElectricityInfo())
    }, [dispatch]);

    if (!showWrappedInfo) {
      return (
        <Grid>
          <GridCell span={12}>
            <Button unelevated onClick={() => setShowWrappedInfo(true)}>
              Show Wrapped 2024
            </Button>
          </GridCell>
        </Grid>
      )
    }

    const onCarouselClose = () => {
      if (UsingApp()) {
        redirectToAppContainerHome();
      } else {
        setShowWrappedInfo(false);
      }
    }

    const onShareClick = () => {
      const title = intl.formatMessage({ id: "Wrapped2024.Share.Title" });
      const url = `${baseUrl}/wrapped-2024`;
      if (UsingApp()) {
        shareDataToAppContainer(title, url);
      } else {
        window.navigator.share({ title, text: title, url });
      }
    }

    return (
      <div className={classNames('wrapped wrapped__container', { 'wrapped--mobile': isMobile }, intl.locale)}>
        <div className='wrapped__carousel'>
          <Carousel onClose={onCarouselClose} fullHeight={true} slideOnClick={true}>
            <Intro />
            {data?.ElectricityGeneral && <ElectricityGeneral data={data?.ElectricityGeneral}/>}
            {data?.ElectricityPersonalFix && <ElectricityPersonalFix data={data?.ElectricityPersonalFix}/>}
            {data?.ElectricityPersonalPingevaba && <ElectricityPersonalPingevaba />}
            {data?.ElectricityPersonalBors && <ElectricityPersonalBors />}
            {data?.GasGeneral && <GasGeneral data={data?.GasGeneral}/>}
            {data?.GasPersonal && <GasPersonal />}
            {data?.FoodGeneral && <FoodGeneral data={data?.FoodGeneral}/>}
            {data?.FoodTop3 && <FoodTop3 data={data?.FoodTop3}/>}
            {data?.FoodSingle && <FoodSingle data={data?.FoodSingle}/>}
            {data?.DrinkGeneral && <DrinkGeneral data={data?.DrinkGeneral}/>}
            {data?.DrinkRefillableCup && <DrinkRefillable />}
            {data?.DrinkDisposableCup && <DrinkDisposable data={data?.DrinkDisposableCup}/>}
            {data?.CylinderGasGeneral && <CylinderGasGeneral />}
            {data?.CylinderGasPersonal && <CylinderGasPersonal data={data?.CylinderGasPersonal}/>}
            {data?.FuelGeneral && <FuelGeneral />}
            {data?.FuelPersonal && <FuelPersonal data={data?.FuelPersonal}/>}
            {data?.EvChargingGeneral && <EvChargingGeneral />}
            {data?.EvChargingHomeCharger && <EvChargingPersonal type="home" data={data?.EvChargingHomeCharger}/>}
            {data?.EvChargingPublicCharger && <EvChargingPersonal type="public" data={data?.EvChargingPublicCharger}/>}
            {data?.CommunityGeneral && <CommunityGeneral data={data?.CommunityGeneral}/>}
            {data?.CommunityDay && <CommunityDay />}
            {data?.CommunityMember && <CommunityMember data={data?.CommunityMember}/>}
            {data?.RenewableEnergyGeneral && <RenewableEnergyGeneral />}
            {data?.RenewableEnergyRohesarts && <RenewableEnergyRohesarts data={data?.RenewableEnergyRohesarts}/>}
            {data?.SmartElectricityGeneral && <SmartElectricityGeneral data={data?.SmartElectricityGeneral}/>}
            {data?.TrailerGeneral && <TrailerGeneral data={data?.TrailerGeneral}/>}
            {data?.TrailerLongRent && <TrailerPersonal type="long" data={data?.TrailerLongRent}/>}
            {data?.TrailerShortRent && <TrailerPersonal type="short" data={data?.TrailerShortRent}/>}
            {data?.Loyalty && <Loyalty index={0} data={data?.Loyalty}/>}
            {data?.Loyalty && <Loyalty index={1} data={data?.Loyalty}/>}
            {data?.Loyalty && <Loyalty index={2} data={data?.Loyalty}/>}
            {data?.LoyaltyPersonal && <LoyaltyPersonal data={data?.LoyaltyPersonal}/>}
            <Outro campaignInfo={campaignInfo} onClose={onCarouselClose}/>
          </Carousel>
        </div>
        <Button
          onClick={onShareClick}
          className="wrapped__share-btn"
          icon={{
            icon: "share",
            strategy: "className",
            basename: "icon",
            prefix: "icon-",
            className: "wrapped__share-btn__icon",
          }}
        >
          <FormattedMessage id="Wrapped2024.Share.Btn" />
        </Button>
      </div>
    )
};

export default withFeature(Wrapped2024)(Wrapped)
