import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/drink_refillable/Jook korduv tops.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/drink_refillable/Jook korduv tops@2x.png";
import slideBg2 from "../../../images/wrapped_2024/slides/drink_refillable/Jook korduv tops 2.png";
import slideBg2_2x from "../../../images/wrapped_2024/slides/drink_refillable/Jook korduv tops 2@2x.png";
import React from "react";
import coffee from "../../../images/wrapped_2024/slides/drink_refillable/Mask group.png";
import coffee_2x from "../../../images/wrapped_2024/slides/drink_refillable/Mask group@2x.png";

export const DrinkRefillable = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__drink-refillable'>
            <div className='wrapped__drink-refillable__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.DrinkRefillable.Title"/></p>
                <img src={coffee} srcSet={`${coffee} 1x, ${coffee_2x} 2x`} alt="bath"/>
                <h1><FormattedHTMLMessage id="Wrapped2024.DrinkRefillable.Pat"/></h1>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
            <img src={slideBg2} srcSet={`${slideBg2} 1x, ${slideBg2_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__foreground'/>
        </div>
    );
}