import * as React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';
import {IconButton} from '@rmwc/icon-button';
import {defaultIconProps} from '../common/Icon';
import logoIcon from '../../images/logo-icon.svg';

import '../../styles/react-components/carousel.scss';

const CAROUSEL_PAGING_DOT_CLASS_NAME = 'carousel__paging-dot';

const Carousel = (props) => {
  const [isPlaying, setIsPlaying] = React.useState(true);
  const sliderRef = React.useRef(null);
  const wrapperRef = React.useRef(null);

  const settings = React.useMemo(() => ({
    dots: Array.isArray(props.children),
    arrows: false,
    dotsClass: 'carousel__paging',
    customPaging: () => <div className={CAROUSEL_PAGING_DOT_CLASS_NAME} />,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    ...props.settings,
  }), [props.settings]);

  const onPlayPauseBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPlaying) {
      setIsPlaying(false);
      sliderRef.current.slickPause();
    } else {
      setIsPlaying(true);
      sliderRef.current.slickPlay();
    }
  }

  return (
    <div
      className={classNames('carousel__wrapper', { 'carousel__wrapper--full-height': props.fullHeight })}
      onClick={(e) => {
        if (!e.target?.classList?.contains(CAROUSEL_PAGING_DOT_CLASS_NAME) && props.slideOnClick) {
          const divWidth = wrapperRef.current.getBoundingClientRect().width;
          const halfDivWidth = divWidth / 2;
          const mouseXPos = e.clientX;
          if (mouseXPos <= halfDivWidth) {
            sliderRef.current.slickPrev();
          } else {
            sliderRef.current.slickNext();
          }
          if (isPlaying) {
            sliderRef.current.slickPlay();
          }
        }
      }}
      ref={wrapperRef}
    >
      <div className='carousel__header'>
        <img src={logoIcon} alt='logo-icon' />
        <div className='carousel__controls'>
          <IconButton
            onIcon={{ ...defaultIconProps, icon: isPlaying ? 'pause' : 'arrow-right' }}
            onClick={onPlayPauseBtnClick}
            className='carousel__control_btn carousel__control_btn--pause'
          />
          {props.onClose && (
            <IconButton
              onIcon={{ ...defaultIconProps, icon: 'close' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClose();
              }}
              className='carousel__control_btn'
            />
          )}
        </div>
      </div>
      <Slider
        {...settings}
        className={classNames('carousel', props.className, { 'carousel--full-height': props.fullHeight })}
        ref={sliderRef}
      >
        {props.children}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.any.isRequired,
  settings: PropTypes.object,
  onClose: PropTypes.func,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  slideOnClick: PropTypes.bool,
};

export default Carousel;
