import {FormattedHTMLMessage, FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";
import globe from "../../../images/wrapped_2024/slides/fuel_personal/nasa-vhSz50AaFAs-unsplash 1.png";
import golf from "../../../images/wrapped_2024/slides/fuel_personal/vw-golf-bensiin 1.png";
import skoda from "../../../images/wrapped_2024/slides/fuel_personal/sm_251119156_skoda-superb-2019-side-view_4x 1.png";
import tesla from "../../../images/wrapped_2024/slides/fuel_personal/tesla-electric 2.png";
import classNames from "classnames";

export const FuelPersonal = ({data}) => {
    const intl = useIntl();

    const getDistanceSelector = () => {
        if (data.totalLength >= 150000) return "Infinity";
        if (data.totalLength >= 120000) return "120000";
        if (data.totalLength >= 100000) return "100000";
        if (data.totalLength >= 80000) return "80000";
        if (data.totalLength >= 40000) return "40000";
        if (data.totalLength >= 20000) return "20000";
        if (data.totalLength >= 15000) return "15000";
        if (data.totalLength >= 14000) return "14000";
        if (data.totalLength >= 13000) return "13000";
        if (data.totalLength >= 12000) return "12000";
        if (data.totalLength >= 11000) return "11000";
        if (data.totalLength >= 10000) return "10000";
        if (data.totalLength >= 9000) return "9000";
        if (data.totalLength >= 8000) return "8000";
        if (data.totalLength >= 7000) return "7000";
        if (data.totalLength >= 6000) return "6000";
        if (data.totalLength >= 5000) return "5000";
        if (data.totalLength >= 4000) return "4000";
        if (data.totalLength >= 3000) return "3000";
        if (data.totalLength >= 2000) return "2000";
        if (data.totalLength >= 1000) return "1000";
    }

    const getVehicleImage = (fuel) => {
        if (fuel === "Ev")
            return tesla;
        if (fuel === "Diesel")
            return skoda;
        return golf;
    }

    const renderFuelProgress = (fuel, classname) => {
        return (
            <div className="travel">
                <img className="globe" src={globe} alt="globe"/>
                <div className={classNames("progress", fuel.toLowerCase(), classname)}><p><FormattedMessage id={`Wrapped2024.FuelPersonal.${fuel}`}/></p>
                </div>
                <img className={classNames("vehicle", classname)} src={getVehicleImage(fuel)}/>
            </div>
        )
    }

    const isTerrestrialDistance = data.totalLength < 40000;
    const prefix = data.fuels.length === 3 ? "All" : data.fuels[0];
    const suffixId = isTerrestrialDistance ? "Wrapped2024.FuelPersonal.SuffixFurther" : "Wrapped2024.FuelPersonal.SuffixMore";

    return (
        <div className='wrapped__carousel-slide wrapped__fuel-personal'>
            <div className='wrapped__fuel-personal__content'>
                {/* Be careful to align rows below, it might visually ruin the sentence */}
                <p><FormattedMessage id={`Wrapped2024.FuelPersonal.Prefix.${prefix}`}/> {isTerrestrialDistance &&
                    <FormattedMessage id="Wrapped2024.FuelPersonal.FromTallinn"/>} <FormattedMessage
                    id={`Wrapped2024.FuelPersonal.To${getDistanceSelector()}`}/> <FormattedMessage id={suffixId}/></p>
                {renderFuelProgress(data.fuels[0], "top")}
                {data.fuels[1] && renderFuelProgress(data.fuels[1], "mid")}
                {data.fuels[2] && renderFuelProgress(data.fuels[2], "small")}
                <h1><FormattedHTMLMessage id="Wrapped2024.FuelPersonal.Distance"
                                          values={{distance: toDecimalString(data.totalLength, intl.locale)}}/>
                </h1>
            </div>
        </div>
    );
}