import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import wavesBgDarkBlue from '../../images/wrapped_2024/intro/wave-bg-dark-blue.png';
import wavesBgDarkBlue_2x from '../../images/wrapped_2024/intro/wave-bg-dark-blue@2x.png';
import wavesBgTeal from '../../images/wrapped_2024/intro/wave-bg-teal.png';
import wavesBgTeal_2x from '../../images/wrapped_2024/intro/wave-bg-teal@2x.png';
import wavesBgBlue from '../../images/wrapped_2024/intro/wave-bg-blue.png';
import wavesBgBlue_2x from '../../images/wrapped_2024/intro/wave-bg-blue@2x.png';
import yearDices from '../../images/wrapped_2024/intro/year-dices.png';
import yearDices_2x from '../../images/wrapped_2024/intro/year-dices@2x.png';
import logoWhite from '../../images/logo-white.svg'
import {FormattedMessage} from "react-intl";

const Intro = (props) => {
  const [introStep, setIntroStep] = React.useState(1);

  const onIntroClick = (e) => {
    if (introStep < 4) {
      e.preventDefault();
      e.stopPropagation();
      setIntroStep((prev) => prev + 1);
    } else if (props.onClose) {
      props.onClose();
    }
  }
  return (
    <div className={classNames('wrapped__intro', `wrapped__intro-step-${introStep}`)} onClick={onIntroClick}>
      <div className='wrapped__intro-content'>
        <img src={logoWhite} alt='logo-white' className='wrapped__intro-logo'/>
        <h1 className={classNames('wrapped__intro-heading wrapped__intro-heading-01', {
            'wrapped__intro-heading--hidden': introStep !== 1,
          })}
        >
            <FormattedMessage id="Wrapped2024.Intro.InRearViewMirror" />
        </h1>

        <h1 className={classNames('wrapped__intro-heading wrapped__intro-heading-02', {
            'wrapped__intro-heading--hidden': introStep !== 2,
          })}
        >
            <FormattedMessage id="Wrapped2024.Intro.WasABigYear" />
        </h1>

        <h1 className={classNames('wrapped__intro-heading wrapped__intro-heading-03', {
            'wrapped__intro-heading--hidden': introStep !== 3,
          })}
        >
            <FormattedMessage id="Wrapped2024.Intro.ViewInMirror" />
        </h1>

        <h1 className={classNames('wrapped__intro-heading wrapped__intro-heading-04', {
            'wrapped__intro-heading--hidden': introStep !== 4,
          })}
        >
            <FormattedMessage id="Wrapped2024.Intro.LookBack" />
        </h1>
      </div>

      {/* Absolute positioned backgrounds */}
      <img
        src={wavesBgBlue}
        srcSet={`${wavesBgBlue} 1x, ${wavesBgBlue_2x} 2x`}
        alt='waves-bg-blue'
        className={classNames('wrapped__intro-waves-blue', {
          'wrapped__intro-waves-blue--lifted': introStep > 1
        })}
      />
      <img
        src={yearDices}
        srcSet={`${yearDices} 1x, ${yearDices_2x} 2x`}
        alt='year-dices'
        className={classNames('wrapped__intro-year-dices', {'wrapped__intro-year-dices--lifted': introStep > 1})}
      />
      <img
        src={wavesBgTeal}
        srcSet={`${wavesBgTeal} 1x, ${wavesBgTeal_2x} 2x`}
        alt='waves-bg-teal'
        className={classNames('wrapped__intro-waves-teal', {
          'wrapped__intro-waves-teal--lifted-01': introStep === 2,
          'wrapped__intro-waves-teal--lifted-02': introStep > 2,
        })}
      />
      <img
        src={wavesBgDarkBlue}
        srcSet={`${wavesBgDarkBlue} 1x, ${wavesBgDarkBlue_2x} 2x`}
        alt='waves-bg-dark-blue'
        className={classNames('wrapped__intro-waves-dark-blue', {
          'wrapped__intro-waves-dark-blue--lifted': introStep > 3,
        })}
      />
    </div>
  );
};

Intro.propTypes = {
  onClose: PropTypes.func,
};

export default Intro;
