import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/cylinder_gas_general/Balloonigaas general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/cylinder_gas_general/Balloonigaas general@2x.png";
import cabinet from "../../../images/wrapped_2024/slides/cylinder_gas_general/_MG_9885-2 1.png";
import cabinet_2x from "../../../images/wrapped_2024/slides/cylinder_gas_general/Balloonigaas general@2x.png";
import React from "react";

export const CylinderGasGeneral = () => {
    return (
        <div className='wrapped__carousel-slide wrapped__cylinder-gas-general'>
            <div className='wrapped__cylinder-gas-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.CylinderGasGeneral.Installed"/></p>
                <div>
                    <img src={cabinet} srcSet={`${cabinet} 1x, ${cabinet_2x} 2x`} alt="cabinet"/>
                    <h1><FormattedHTMLMessage id="Wrapped2024.CylinderGasGeneral.Batteries"/></h1>
                </div>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
        </div>
    );
}