import {FormattedHTMLMessage, useIntl} from "react-intl";
import piggy from "../../../images/wrapped_2024/slides/electricity_personal_pingevaba/piggy.png";
import piggy_2x from "../../../images/wrapped_2024/slides/electricity_personal_pingevaba/piggy@2x.png";
import React from "react";
import {toDecimalString} from "../../../helpers/numberHelper";

export const ElectricityPersonalPingevaba = () => {
    const intl = useIntl();
    return (
      <div className='wrapped__carousel-slide wrapped__el-personal-pingevaba'>
          <div className='wrapped__el-personal-pingevaba__content'>
              <p className="foreground"><FormattedHTMLMessage id="Wrapped2024.ElectricityPersonalPingevaba.Title"/></p>
              <div className="tableRow">
                  <h3><FormattedHTMLMessage id="Wrapped2024.General.May"/>:</h3>
                  <span className="top"><h1>{toDecimalString(9.01, intl.locale, 2)}</h1><h3>s/kWh</h3></span>
              </div>
              <div className="tableRow">
                  <h3><FormattedHTMLMessage id="Wrapped2024.General.April"/>:</h3>
                  <h3>{toDecimalString(10.01, intl.locale, 2)} s/kWh</h3>
              </div>
              <div className="tableRow">
                  <h3><FormattedHTMLMessage id="Wrapped2024.General.June"/>:</h3>
                  <h3>{toDecimalString(10.01, intl.locale, 2)} s/kWh</h3>
              </div>
          </div>

          <img
            src={piggy}
            srcSet={`${piggy} 1x, ${piggy_2x} 2x`}
            alt='piggy'
            className="wrapped__el-personal-pingevaba__piggy"
          />
      </div>
    );
}
