import React from 'react';
import slideBg from '../../../images/wrapped_2024/slides/electricity_personal_fix/Elekter person fix.png';
import sofa from "../../../images/wrapped_2024/slides/electricity_personal_fix/sofa.png";
import sofa_2x from "../../../images/wrapped_2024/slides/electricity_personal_fix/sofa@x2.png";
import {FormattedHTMLMessage, useIntl} from "react-intl";
import {toDecimalString} from "../../../helpers/numberHelper";
import slideBg_2x from "../../../images/wrapped_2024/slides/electricity_personal_fix/Elekter person fix@x2.png";

const ElectricityPersonalFix = ({data}) => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__el-personal-fix'>
            <div className='wrapped__el-personal-fix__content'>
                <h1><FormattedHTMLMessage id="Wrapped2024.ElectricityPersonalFix.Title"/></h1>
                <img src={sofa} srcSet={`${sofa} 1x, ${sofa_2x} 2x`} alt='sofa'/>
                <div>
                    <h3><FormattedHTMLMessage id="Wrapped2024.ElectricityPersonalFix.Line1"/></h3>
                    <h1>{toDecimalString(data.maxBorsPrice, intl.locale, 1)} €/kWh</h1>
                </div>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
};

export default ElectricityPersonalFix;
