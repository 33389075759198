import {FormattedHTMLMessage, useIntl} from "react-intl";
import {toDecimalString} from "../../../helpers/numberHelper";
import slideBg from "../../../images/wrapped_2024/slides/food_general/Toit general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/food_general/Toit general@2x.png";
import hotDog from "../../../images/wrapped_2024/slides/food_general/KABANOSS-wrapped-3 1.png";
import hotDog_2x from "../../../images/wrapped_2024/slides/food_general/KABANOSS-wrapped-3 1@2x.png";
import React from "react";

export const FoodGeneral = ({data}) => {
    const intl = useIntl();
    return (
        <div className='wrapped__carousel-slide wrapped__food-general'>
            <div className='wrapped__food-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.FoodGeneral.PopularFood"/></p>
                <h1><FormattedHTMLMessage id="Wrapped2024.FoodGeneral.LenthOfSausages"
                                          values={{length: toDecimalString(data.totalLengthOfSausagesKm, intl.locale, 1)}}/>
                </h1>
                <p><FormattedHTMLMessage id="Wrapped2024.FoodGeneral.Distance"/></p>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
          <div className="imageFrame">
            <img src={hotDog} srcSet={`${hotDog} 1x, ${hotDog_2x} 2x`} alt='hotDog-bg'/>
          </div>
        </div>
    );
}