import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/drink_general/Jook general.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/drink_general/Jook general@2x.png";
import bath from "../../../images/wrapped_2024/slides/drink_general/175014_2764014M2020_01_original-4 1.png";
import bath_2x from "../../../images/wrapped_2024/slides/drink_general/175014_2764014M2020_01_original-4 1@2x.png";
import React from "react";

export const DrinkGeneral = ({data}) => {
    return (
        <div className='wrapped__carousel-slide wrapped__drink-general'>
            <div className='wrapped__drink-general__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.DrinkGeneral.Favourite"/></p>
                <img src={bath} srcSet={`${bath} 1x, ${bath_2x} 2x`} alt="bath" />
                <h1><span>{data.amountOfBaths}</span><br/><FormattedHTMLMessage id="Wrapped2024.DrinkGeneral.BathsOfCoffee"/></h1>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg' className='wrapped__carousel-slide__background'/>
        </div>
    );
}