import {FormattedHTMLMessage} from "react-intl";
import balloonOne from "../../../images/wrapped_2024/slides/cylinder_gas_personal/Balloonigaas 1 balloon.png";
import balloonOne_2x from "../../../images/wrapped_2024/slides/cylinder_gas_personal/Balloonigaas 1 balloon@2x.png";
import balloonMore from "../../../images/wrapped_2024/slides/cylinder_gas_personal/Balloonigaas 2 ja rohkem.png";
import balloonMore_2x from "../../../images/wrapped_2024/slides/cylinder_gas_personal/Balloonigaas 2 ja rohkem@2x.png";
import React from "react";

export const CylinderGasPersonal = ({data}) => {
    const slideBg = data.moreThanOne ? balloonMore : balloonOne;
    const slideBg_2x = data.moreThanOne ? balloonMore_2x : balloonOne_2x;
    return (
        <div className='wrapped__carousel-slide wrapped__cylinder-gas-personal'>
            <div className='wrapped__cylinder-gas-personal__content'>
                <p><FormattedHTMLMessage
                    id={data.moreThanOne ? "Wrapped2024.CylinderGasPersonal.Busy" : "Wrapped2024.CylinderGasPersonal.Economical"}/>
                </p>
                <h1><FormattedHTMLMessage
                    id={data.moreThanOne ? "Wrapped2024.CylinderGasPersonal.AmountStored" : "Wrapped2024.CylinderGasPersonal.Amount"}
                    values={{amount: data.totalWeight}}/></h1>
            </div>

            <div className="imageFrame">
                <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='cyclinder-gas'
                     className="wrapped__cylinder-gas-personal__background-gas"/>
            </div>
        </div>
    );
}