import {FormattedHTMLMessage} from "react-intl";
import slideBg from "../../../images/wrapped_2024/slides/drink_disposable/Jook ühekordne kohvi.png";
import slideBg_2x from "../../../images/wrapped_2024/slides/drink_disposable/Jook ühekordne kohvi@2x.png";
import React from "react";
import coffee from "../../../images/wrapped_2024/slides/drink_disposable/_MG_8811 1.png";
import coffee_2x from "../../../images/wrapped_2024/slides/drink_disposable/_MG_8811 1@2x.png";

export const DrinkDisposable = ({data}) => {
    return (
        <div className='wrapped__carousel-slide wrapped__drink-disposable'>
            <div className='wrapped__drink-disposable__content'>
                <p><FormattedHTMLMessage id="Wrapped2024.DrinkDisposable.Title" values={{count: data.count}}/></p>
                <img src={coffee} srcSet={`${coffee} 1x, ${coffee_2x} 2x`} alt="bath"/>
                <div>
                    <p><FormattedHTMLMessage id="Wrapped2024.DrinkDisposable.TryRefillable"/></p>
                    <h1><FormattedHTMLMessage id="Wrapped2024.DrinkDisposable.ATree"/></h1>
                </div>
            </div>

            <img src={slideBg} srcSet={`${slideBg} 1x, ${slideBg_2x} 2x`} alt='slide-bg'
                 className='wrapped__carousel-slide__background'/>
        </div>
    );
}