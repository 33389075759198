import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import classNames from "classnames";
import {Button} from "@rmwc/button";

import {ContractElectricityPath, FuelPlusElectricityPath} from "../../const/routes";
import {RedirectToFuelPlusElectricity} from "../FuelPlusElectricity/FuelPlusElectricitySign";
import {
  redirectToAppContainerElectricityContractsView,
  // redirectToAppContainerFuelPlusElectricityCampaignView,
  redirectToExternalUrl,
} from "../../helpers/appContainerHelper";
import {fetchConsumptionLocations} from "../../actions/consumptionlocationActions";
import logo from "../../images/wrapped_2024/outro/Logo.svg";
import yearDices from "../../images/wrapped_2024/intro/year-dices.png";
import yearDices_2x from "../../images/wrapped_2024/intro/year-dices@2x.png";
import offer from "../../images/wrapped_2024/outro/offer.png";
import offer_2x from "../../images/wrapped_2024/outro/offer@2x.png";
import waveBg from "../../images/wrapped_2024/outro/wave-bg.png";
import waveBg_2x from "../../images/wrapped_2024/outro/wave-bg@2x.png";
import {FuelPlusElectricityCampaign} from "../FuelPlusElectricity/FuelPlusElectricityRegister";

export const Outro = ({campaignInfo, onClose}) => {
    const isCombinedAppContainer = useSelector(state => state.appContainer.isCombinedAppContainer);
    const {data: consumptionLocations} = useSelector(state => state.consumptionLocations);
    const [outroStep, setOutroStep] = React.useState(1);
    const history = useHistory();
    const dispatch = useDispatch();
    const wrappedCampaign = "wrapped";

    useEffect(() => {
        dispatch(fetchConsumptionLocations());
    }, [dispatch]);

    const onOutroClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (consumptionLocations?.locations?.length > 0 && !campaignInfo.isFuelPlusElectricityCampaignActive && outroStep < 3) {
            setOutroStep((prev) => prev + 1);
        } else if (onClose) {
            onClose();
        }
    }

    const redirectToCampaign = (e) => {
        e.preventDefault();
        e.stopPropagation();
        localStorage.setItem(FuelPlusElectricityCampaign, wrappedCampaign);

        if (isCombinedAppContainer) {
            // TODO: ITK-2243 - In case if native application will add mapping for next redirecting, then uncomment next line and delete further "redirectToExternalUrl" call. (do not forget to uncomment function import)
            // redirectToAppContainerFuelPlusElectricityCampaignView()
            redirectToExternalUrl('/fuel-plus-electricity');
        } else {
            history.push(FuelPlusElectricityPath);
        }
    }

    const redirectToElectricity = (e) => {
        e.preventDefault();
        e.stopPropagation();
        localStorage.setItem(FuelPlusElectricityCampaign, wrappedCampaign);

        if (isCombinedAppContainer) {
            redirectToAppContainerElectricityContractsView()
        } else {
            localStorage.setItem(RedirectToFuelPlusElectricity, true);
            history.push(ContractElectricityPath);
        }
    }

    const renderOfferButton = () => {
        if (campaignInfo?.hasElectricityContract) {
            return <Button
                trailingIcon={{
                    icon: "angle-right",
                    strategy: "className",
                    basename: "icon",
                    prefix: "icon-",
                }}
                onClick={redirectToCampaign}>
                <FormattedMessage id="Wrapped2024.Outro.SeeTheOffer"/>
            </Button>
        }

        if (!campaignInfo?.hasElectricityContract) {
            return <Button
                trailingIcon={{
                    icon: "angle-right",
                    strategy: "className",
                    basename: "icon",
                    prefix: "icon-",
                }}
                onClick={redirectToElectricity}>
                <FormattedMessage id="Wrapped2024.Outro.ToChooseAPackage"/>
            </Button>
        }
    }

    return (
        <div className={classNames('wrapped__outro', `wrapped__outro-step-${outroStep}`)} onClick={onOutroClick}>
            <div className={classNames('wrapped__outro-content', {'wrapped__outro-content--hidden': outroStep !== 1,})}>
              <h1 className='wrapped__outro-heading wrapped__outro-heading-1-1'>
                <FormattedHTMLMessage id="Wrapped2024.Outro.Title"/>
              </h1>
              <img src={logo} alt='logo' className='wrapped__outro-logo'/>
              <h1 className='wrapped__outro-heading wrapped__outro-heading-1-2'>
                <FormattedHTMLMessage id="Wrapped2024.Outro.InRearViewMirror"/>
              </h1>
            </div>

          <div className={classNames('wrapped__outro-content', {'wrapped__outro-content--hidden': outroStep !== 2,})}>
                <h1 className='wrapped__outro-heading wrapped__outro-heading-2-1'>
                    <FormattedHTMLMessage id="Wrapped2024.Outro.One"/>
                </h1>
                <h1 className='wrapped__outro-heading wrapped__outro-heading-2-2'>
                    <FormattedHTMLMessage id="Wrapped2024.Outro.ThingMore"/>
                </h1>
                <div className="actions2">
                    <Button
                        trailingIcon={{
                            icon: "angle-right",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                        }}>
                        <FormattedMessage id="Wrapped2024.Outro.Look"/>
                    </Button>
                </div>
            </div>

            <div className={classNames('wrapped__outro-content', {'wrapped__outro-content--hidden': outroStep !== 3,})}>
                <h1 className='wrapped__outro-heading wrapped__outro-heading-3-1'>
                    <FormattedHTMLMessage
                        id={campaignInfo?.hasElectricityContract ? "Wrapped2024.Outro.FuelOffer" : "Wrapped2024.Outro.SwitchOnElectricity"}/>
                </h1>
                <div className="actions3">
                    <Button icon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }} onClick={onClose}/>
                    {renderOfferButton()}
                </div>
            </div>

            <img
                src={yearDices}
                srcSet={`${yearDices} 1x, ${yearDices_2x} 2x`}
                alt='year-dices'
                className={classNames('wrapped__outro-year-dices', {'wrapped__outro-year-dices--hidden': outroStep > 1})}
            />

            <img
                src={waveBg}
                srcSet={`${waveBg} 1x, ${waveBg_2x} 2x`}
                alt='offer'
                className={classNames('wrapped__carousel-slide__background wrapped__outro-wave-bg', {'wrapped__outro-offer--hidden': outroStep !== 3})}
            />

            <div className="wrapped__outro-offer-wrapper">
                <img
                  src={offer}
                  srcSet={`${offer} 1x, ${offer_2x} 2x`}
                  alt='offer'
                  className={classNames('wrapped__outro-offer', {'wrapped__outro-offer--hidden': outroStep !== 3})}
                />
            </div>
        </div>
    );
}
